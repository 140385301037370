import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

import { AlertCircle } from 'lucide-react'
import type { Website } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { Alert, AlertDescription } from '@/components/ui/Alert'
import { Button } from '@/components/ui/Button'
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
  CardTitle,
} from '@/components/ui/Card'

const GET_STRIPE_CHECKOUT_REDIRECT_URL_FOR_PLAN = gql`
  mutation GetStripeCheckoutRedirectUrlForPlanSettingsPanelMutation(
    $websiteId: String!
    $planName: String!
    $isAnnual: Boolean!
    $promoCode: String
  ) {
    getStripeCheckoutRedirectUrlForPlan(
      websiteId: $websiteId
      planName: $planName
      isAnnual: $isAnnual
      promoCode: $promoCode
    )
  }
`

const UPDATE_WEBSITE_STRIPE_PLAN = gql`
  mutation UpdateWebsiteStripePlanSettingsPanelMutation(
    $websiteId: String!
    $planName: String!
    $isAnnual: Boolean!
  ) {
    updateWebsiteStripePlan(
      websiteId: $websiteId
      planName: $planName
      isAnnual: $isAnnual
    )
  }
`

type PlanSettingsProps = {
  website: Website
}

// Custom Switch component
const Switch = ({ checked, onCheckedChange }) => {
  return (
    <button
      type="button"
      role="switch"
      aria-checked={checked}
      onClick={() => onCheckedChange(!checked)}
      className={`
        relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full
        transition-colors duration-200 ease-in-out
        focus-visible:outline-none focus-visible:ring-2
        focus-visible:ring-offset-2 focus-visible:ring-offset-white
        ${checked ? 'bg-blue-600' : 'bg-gray-200'}
      `}
    >
      <span
        className={`
          pointer-events-none inline-block h-5 w-5 translate-y-[2px] transform
          rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out
          ${checked ? 'translate-x-[1.4rem]' : 'translate-x-[0.1rem]'}
        `}
      />
    </button>
  )
}

const formatCurrency = (amount, currencyCode) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount)
}

const PriceDisplay = ({ plan, isAnnual, isLoadingPricingData }) => {
  if (isLoadingPricingData) {
    return (
      <div>
        <i className="fa-regular fa-spinner-third fa-spin" />
      </div>
    )
  }

  if (plan.price === 'Custom') {
    return 'Custom'
  }

  const hasLocalPrice =
    'localMonthlyPrice' in plan && 'localYearlyPrice' in plan
  const secondaryPrice = hasLocalPrice
    ? isAnnual
      ? plan.localYearlyPrice
      : plan.localMonthlyPrice
    : null

  const mainPrice = hasLocalPrice
    ? isAnnual
      ? plan.yearlyPrice
      : plan.monthlyPrice
    : isAnnual
    ? plan.yearlyPrice
    : plan.monthlyPrice

  return (
    <div className="flex flex-col">
      <div className="flex items-baseline gap-1">
        <span className="text-3xl font-bold">
          {formatCurrency(mainPrice, 'USD')}
        </span>
        <span className="text-sm text-gray-500">/mo</span>
      </div>
      {hasLocalPrice && (
        <div className="text-gray-500">
          <span className="mr-1 text-[18px]">{plan.countryFlag}</span>
          <span className="text-sm">
            {formatCurrency(
              secondaryPrice,
              hasLocalPrice ? plan.currencyCode : 'USD'
            )}
            /mo
          </span>
        </div>
      )}
    </div>
  )
}

const PricingCard = ({
  plan,
  isAnnual,
  isEnterprise = false,
  isCurrentPlan = false,
  onClick,
  isLoadingPricingData = false,
  error = null,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const features = plan.features.map((feature, index) => (
    <div key={index} className="flex items-center space-x-2">
      <i className="fa-regular fa-check text-green-500" />
      <span className="text-sm text-gray-600">{feature}</span>
    </div>
  ))

  if (isEnterprise) {
    return (
      <Card className="mt-8">
        <div className="grid grid-cols-1 items-center gap-6 p-6">
          <div className="space-y-2">
            <h3 className="text-xl font-bold">{plan.name}</h3>
            <p className="text-sm text-gray-500">For enterprise sites</p>
          </div>
          <div className="space-y-3">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {features}
            </div>
          </div>
          <div className="">
            <Button size="lg">
              <span>Contact</span>
              <span className="hidden sm:block" translate="no">
                support@landingsite.ai
              </span>
            </Button>
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card
      className={`flex h-full flex-col ${
        plan.name === 'Pro' ? 'border-blue-500 shadow-lg' : ''
      }`}
    >
      <CardHeader>
        <CardTitle className="flex flex-col gap-2">
          <h3 className="text-xl font-bold">{plan.name}</h3>
          <PriceDisplay
            plan={plan}
            isAnnual={isAnnual}
            isLoadingPricingData={isLoadingPricingData}
          />
          {plan.subtitle && (
            <p className="text-sm text-gray-500">{plan.subtitle}</p>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="space-y-3">{features}</div>
      </CardContent>
      <CardFooter className="flex flex-col gap-2">
        <Button
          className="w-full"
          variant={plan.name === 'Pro' ? 'default' : 'outline'}
          onClick={async () => {
            setIsLoading(true)
            await onClick(plan.name)
            setIsLoading(false)
          }}
          disabled={isLoading || isCurrentPlan}
        >
          {isLoading ? (
            <i className="fa-regular fa-spinner-third fa-spin" />
          ) : isCurrentPlan ? (
            'Current Plan'
          ) : (
            <>
              <span>Select {plan.name} Plan</span>
            </>
          )}
        </Button>
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </CardFooter>
    </Card>
  )
}

// This function fetches the pricing data and sets it in state
const fetchPricingData = async () => {
  try {
    const response = await fetch(
      'https://prod-get-prices.getlandingsite.workers.dev/?newPlans=true'
    )
    const data = await response.json()

    return data
  } catch (error) {
    console.error('Failed to fetch pricing data:', error)
  }

  return null
}

const PlanSettings = ({ website }: PlanSettingsProps) => {
  const [isAnnual, setIsAnnual] = useState(
    website?.stripePlan === 'Free'
      ? true
      : website?.stripeIsAnnualPlan === false
      ? false
      : true
  )
  const [isLoadingPricingData, setIsLoadingPricingData] = useState(true)
  const [pricingData, setPricingData] = useState(null)
  const [currentStripePlan, setCurrentStripePlan] = useState(
    website?.stripePlan || 'Free'
  )
  const [currentStripeIsAnnualPlan, setCurrentStripeIsAnnualPlan] = useState(
    website?.stripeIsAnnualPlan === true ? true : false
  )

  const [errors, setErrors] = useState({})

  const defaultPlans = [
    {
      name: 'Basic',
      monthlyPrice: 10,
      yearlyPrice: 5,
      subtitle: 'per website per month',
      features: [
        'For simple websites',
        'Custom domain',
        'Homepage only',
        '50 form submissions/mo',
        '1,000 visitors/mo',
      ],
    },
    {
      name: 'Pro',
      monthlyPrice: 20,
      yearlyPrice: 15,
      subtitle: 'per website per month',
      features: [
        'For business websites',
        'Custom domain',
        '150 pages',
        '500 form submissions/mo',
        '10,000 visitors/mo',
      ],
    },
    {
      name: 'Scale',
      monthlyPrice: 40,
      yearlyPrice: 30,
      subtitle: 'per website per month',
      features: [
        'For larger websites',
        'Custom domain',
        '300 pages',
        '2,500 form submissions/mo',
        '200,000 visitors/mo',
      ],
    },
  ]

  const [plans, setPlans] = useState(defaultPlans)

  useEffect(() => {
    const getPricingData = async () => {
      setIsLoadingPricingData(true)
      try {
        const data = await fetchPricingData()

        setPricingData(data)
      } catch (e) {
        console.error(e)
      }

      setIsLoadingPricingData(false)
    }

    getPricingData()
  }, [])

  useEffect(() => {
    if (pricingData) {
      const newPlans = plans.map((plan) => {
        const planData = pricingData.find((p) => p.name === plan.name)
        return {
          name: plan.name,
          monthlyPrice: planData?.monthlyPrice,
          yearlyPrice: planData?.yearlyPrice,
          ...(planData?.localMonthlyPrice && {
            localMonthlyPrice: planData?.localMonthlyPrice,
          }),
          ...(planData?.localYearlyPrice && {
            localYearlyPrice: planData?.localYearlyPrice,
          }),
          currencyCode: planData?.currencyCode || 'USD',
          couponCode: planData?.couponCode,
          subtitle: plan.subtitle,
          features: plan.features,
          countryFlag: planData?.countryFlag,
          countryFlagSvg: planData?.countryFlagSvg,
        }
      })

      setPlans(newPlans)
    }
  }, [pricingData])

  const isUpgradingRef = useRef(false)

  const [getStripeCheckoutRedirectUrlForPlan] = useMutation(
    GET_STRIPE_CHECKOUT_REDIRECT_URL_FOR_PLAN
  )

  const [updateWebsiteStripePlan] = useMutation(UPDATE_WEBSITE_STRIPE_PLAN)

  const onPlanClick = async (planName) => {
    if (isUpgradingRef.current === true) {
      return
    }
    isUpgradingRef.current = true

    console.log(pricingData)

    //already on this plan
    if (
      currentStripePlan === planName &&
      currentStripeIsAnnualPlan === isAnnual
    ) {
      isUpgradingRef.current = false
      return
    }

    let promoCode = null
    if (pricingData && pricingData.length > 0) {
      const plan = pricingData.find((p) => p.name === planName)
      if (plan) {
        promoCode = plan.couponCode
      }
    }

    //get promo code from url params for testing locally
    const urlParams = new URLSearchParams(window.location.search)
    const promoCodeParam = urlParams.get('promoCode')
    if (promoCodeParam) {
      promoCode = promoCodeParam
    }

    if (currentStripePlan !== 'Free') {
      try {
        const res = await updateWebsiteStripePlan({
          variables: {
            websiteId: website.id,
            planName,
            isAnnual,
          },
        })

        console.log(res)

        if (
          res.data.updateWebsiteStripePlan === 'open' ||
          res.data.updateWebsiteStripePlan === 'timeout'
        ) {
          setErrors((prev) => ({
            ...prev,
            [planName]:
              'Failed to charge card. Please contact support@landingsite.ai',
          }))
          return
        }

        setErrors((prev) => ({ ...prev, [planName]: null }))

        setCurrentStripeIsAnnualPlan(isAnnual)
        setCurrentStripePlan(planName)
      } catch (e) {
        console.error(e)
        setErrors((prev) => ({
          ...prev,
          [planName]:
            'There was an error. Contact support@landingsite.ai if it keeps happening.',
        }))
      }
    } else {
      try {
        const res = await getStripeCheckoutRedirectUrlForPlan({
          variables: {
            websiteId: website.id,
            planName,
            isAnnual,
            promoCode,
          },
        })

        console.log(res)

        setErrors((prev) => ({ ...prev, [planName]: null }))

        window.location.href = res.data.getStripeCheckoutRedirectUrlForPlan
      } catch (e) {
        console.error(e)
        setErrors((prev) => ({
          ...prev,
          [planName]:
            'There was an error. Contact support@landingsite.ai if it keeps happening.',
        }))
      }
    }
    isUpgradingRef.current = false
  }

  const freePlan = {
    name: 'Free',
    monthlyPrice: 0,
    yearlyPrice: 0,
    subtitle: 'Forever',
    features: ['Generate with AI', 'Edit with AI', 'Website preview'],
  }

  const enterprisePlan = {
    name: 'Enterprise',
    price: 'Custom',
    features: [
      'For enterprise sites',
      'Uptime guarantee',
      'Unlimited pages',
      'Unlimited form submissions',
      'Unlimited visitors',
    ],
  }

  return (
    <div className="container py-8">
      <Card>
        <CardHeader>
          <CardTitle>Plan Settings</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="py-12 sm:px-4">
              <div className="mx-auto max-w-7xl">
                <div className="mb-12 text-center">
                  <h2 className="mb-4 text-3xl font-bold">
                    Simple, transparent pricing
                  </h2>
                  <div className="flex items-center justify-center gap-3">
                    <span className={`text-sm`}>Monthly</span>
                    <Switch checked={isAnnual} onCheckedChange={setIsAnnual} />
                    <span className={`text-sm`}>
                      Annual
                      <span className="ml-1 text-green-600">
                        (Save up to 50%)
                      </span>
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-6 xl:grid-cols-4">
                  <PricingCard
                    plan={freePlan}
                    isAnnual={isAnnual}
                    isCurrentPlan={currentStripePlan === 'Free'}
                    onClick={onPlanClick}
                  />
                  {plans.map((plan) => (
                    <PricingCard
                      key={plan.name}
                      plan={plan}
                      isAnnual={isAnnual}
                      isCurrentPlan={
                        currentStripePlan === plan.name &&
                        isAnnual === currentStripeIsAnnualPlan
                      }
                      onClick={onPlanClick}
                      isLoadingPricingData={isLoadingPricingData}
                      error={errors[plan.name]}
                    />
                  ))}
                </div>

                <PricingCard
                  plan={enterprisePlan}
                  isAnnual={isAnnual}
                  isEnterprise={true}
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default PlanSettings
