import React, { useState, useEffect } from 'react'

import { Website, Page } from 'types/graphql'

import Gallery from 'src/components/Gallery/Gallery'
import { getMediaFromPage, Media } from 'src/lib/media'

const GalleryPanel = ({
  website,
  activePage,
  onClose,
  onMediaSelected,
  initialGalleryType = 'images',
}: {
  website: Website
  activePage: Page
  onClose: () => void
  onMediaSelected: (media: Media) => void
  initialGalleryType?: 'images' | 'videos' | 'illustrations'
}) => {
  const [existingMedia, setExistingMedia] = useState<
    { id: string; src: string; type: string }[]
  >([])
  // load existing media for gallery
  useEffect(() => {
    const getExistingMedia = async () => {
      setExistingMedia(await getMediaFromPage({ page: activePage, website }))
    }
    getExistingMedia()
  }, [activePage, website])

  return (
    <div className="overflow-scroll rounded bg-white p-2 shadow-md">
      <Gallery
        websiteId={website.id}
        initialGalleryType={initialGalleryType}
        initialKeyword={activePage.imagesKeyword}
        existingMedia={existingMedia}
        onClose={onClose}
        onMediaSelected={onMediaSelected}
      />
    </div>
  )
}

export default GalleryPanel
