import { useState } from 'react'

import type { Website } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { Button } from '@/components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card'
import { Alert, AlertDescription } from '@/components/ui/Alert'

const DELETE_WEBSITE_MUTATION = gql`
  mutation DeleteWebsiteMutation($id: String!) {
    deleteWebsite(id: $id) {
      __typename
      id
    }
  }
`

type DeleteSettingsProps = {
  website: Website
}

const DeleteSettings = ({ website }: DeleteSettingsProps) => {
  const [isDeletingWebsite, setIsDeletingWebsite] = useState(false)
  const [deleteErrorString, setDeleteErrorString] = useState<string | null>(
    null
  )

  const [deleteWebsiteMutation] = useMutation(DELETE_WEBSITE_MUTATION)

  const deleteWebsiteClicked = async (event) => {
    event.preventDefault()

    if (confirm('Are you sure you want to delete this website forever?')) {
      setIsDeletingWebsite(true)
      try {
        await deleteWebsiteMutation({
          variables: {
            id: website.id,
          },
        })

        setIsDeletingWebsite(false)
        navigate('/')
      } catch (e) {
        setIsDeletingWebsite(false)
        setDeleteErrorString(e.message)
      }
    }
  }

  return (
    <div className="container max-w-3xl py-8">
      <Card>
        <CardHeader>
          <CardTitle>Delete Website</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <Alert variant="destructive">
            <AlertDescription className="space-y-2">
              <p>
                This will completely delete this website and all the work you've done
              </p>
              <p className="font-semibold">This cannot be undone</p>
            </AlertDescription>
          </Alert>

          {deleteErrorString && (
            <Alert variant="destructive">
              <AlertDescription>{deleteErrorString}</AlertDescription>
            </Alert>
          )}

          <Button
            variant="destructive"
            onClick={deleteWebsiteClicked}
            disabled={isDeletingWebsite}
            size="lg"
          >
            {isDeletingWebsite ? (
              <>
                <i className="fa-regular fa-spinner-third mr-2 animate-spin" />
                Deleting...
              </>
            ) : (
              'Delete This Website'
            )}
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}

export default DeleteSettings
