import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react'

import {
  ArrowRight,
  X,
  ArrowCounterClockwise,
  CircleNotch,
  CrosshairSimple,
} from '@phosphor-icons/react'
import { Prisma } from '@prisma/client'
import clsx from 'clsx'
import { AssistantStream } from 'openai/lib/AssistantStream'
import {
  ImageFile,
  Message,
  Text,
  TextDelta,
} from 'openai/resources/beta/threads/messages'
import {
  RequiredActionFunctionToolCall,
  Run,
} from 'openai/resources/beta/threads/runs/runs'
import {
  ToolCall,
  ToolCallDelta,
} from 'openai/resources/beta/threads/runs/steps'
import { useRemark } from 'react-remark'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { Media } from 'src/lib/media'

import { FrameElementEvent } from '../PageFrame/PageFrame'

const CREATE_UPLOADED_FILE = gql`
  mutation CreateUploadedFileMutation($input: CreateUploadedFileInput!) {
    createUploadedFile(input: $input) {
      __typename
      id
      fileName
      size
      uploadUrl
      s3Url
    }
  }
`

export type ChatPanelHandler = {
  sendAssistantMessage: (message: string) => void
  sendUserMessage: (message: string) => void
  showComponent: (componentName: string) => void
}

type TChatMessage = {
  text: string
  role: string
  elementLabel?: string
  image?: string
  colorPalette?: Prisma.JsonValue
  onColorPaletteKeySaved?: (key: string, value: string) => void
}

type ChatPanelProps = {
  websiteId: string
  clickedElement?: FrameElementEvent
  functions: Record<string, (args?: any) => any>
  handleClearClickedElement: () => void
  disableChatSubmit?: boolean
  onMediaUploaded?: (media: Media) => void
  onSwapImages?: () => void
  isOnboarding?: boolean
  gettingStartedState?:
    | 'edit'
    | 'setupDomain'
    | 'upgrade'
    | 'publish'
    | 'publishing'
    | 'published'
    | 'done'
  onSetUpYourDomainButtonClicked?: () => void
  expanded?: boolean
  onToggleChatPanel?: (expanded: boolean) => void
  upgradeButtonClicked?: () => void
  launchWebsiteButtonClicked?: () => void
  userDomain?: string
  colorPalette?: Prisma.JsonValue
  onColorPaletteChanged?: (colorPalette: Prisma.JsonValue) => void
  onColorPaletteKeySaved?: (key: string, value: string) => void
  chatWaitingText?: string
  onChatUserMessageSubmitted?: (message: string) => void
}
const ChatPanel = forwardRef(
  (
    {
      websiteId,
      functions,
      clickedElement,
      handleClearClickedElement,
      disableChatSubmit,
      onMediaUploaded,
      onSwapImages,
      isOnboarding,
      gettingStartedState,
      onSetUpYourDomainButtonClicked,
      expanded,
      onToggleChatPanel,
      upgradeButtonClicked,
      launchWebsiteButtonClicked,
      userDomain,
      colorPalette,
      onColorPaletteChanged,
      onColorPaletteKeySaved,
      chatWaitingText,
      onChatUserMessageSubmitted,
    }: ChatPanelProps,
    ref
  ) => {
    useImperativeHandle<unknown, ChatPanelHandler>(
      ref,
      () => ({
        sendAssistantMessage: async (message) => {
          await loadPromiseRef.current

          addMessage({ role: 'assistant', text: message })

          //isOnboarding can be null when we are still loading
          if (isOnboarding !== false) {
            return
          }

          // only persist the message if they're logged in
          if (isAuthenticated) {
            sendAssistantMessage(message)
          }
        },
        sendUserMessage: async (message) => {
          console.log('sendUserMessage', message)
          await loadPromiseRef.current
          console.log('sendUserMessage after loadPromiseRef.current')

          handleSubmit(message)
        },
        showComponent: async (componentName) => {
          await loadPromiseRef.current

          //will abstract this later. for now, just show login/signup
          if (componentName === 'loginComponent') {
            addMessage({
              role: 'system',
              text: '<loginComponent>',
            })
          }

          if (componentName === 'colorPalettePicker') {
            addMessage({
              role: 'system',
              text: '<colorPalettePicker>',
              colorPalette,
            })
          }
        },
      }),
      [isOnboarding]
    )

    const [messages, setMessages] = useState<TChatMessage[]>([])
    const [loading, setLoading] = useState(true)
    const [userInput, setUserInput] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(
      disableChatSubmit || false
    )
    const { getToken, isAuthenticated, loading: authLoading } = useAuth()
    const [waiting, setWaiting] = useState(false)
    const [lastOnboardingState, setLastOnboardingState] = useState<boolean>()
    const [shouldShowPublishedModal, setShouldShowPublishedModal] =
      useState(true)

    const loadPromiseRef = useRef<Promise<void> | null>(null)

    const fileInputRef = useRef<HTMLInputElement>(null)
    const [createUploadedFile] = useMutation(CREATE_UPLOADED_FILE)

    const [isExpanded, setIsExpanded] = useState(expanded)

    const [chatLoadErrorMessage, setChatLoadErrorMessage] = useState('')

    useEffect(() => {
      setIsExpanded(expanded)
    }, [expanded])

    useEffect(() => {
      const getMessages = async () => {
        //dont let someone add messages while loading
        loadPromiseRef.current = (async () => {
          setSubmitDisabled(true)
          try {
            const response = await fetch(
              `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
              {
                headers: {
                  Authorization: `Bearer ${await getToken()}`,
                },
              }
            )

            if (response.status === 451) {
              setChatLoadErrorMessage(
                'OpenAI is currently blocked from being used in your region.'
              )
              setLoading(false)
              setSubmitDisabled(true)
              return
            }

            const data = await response.json()
            let messages = data.messages || []

            messages = messages
              .filter((message) => !!message.content[0]?.text?.value)
              .map((message) => ({
                text: message.content[0].text.value,
                role: message.role,
                elementLabel: message.metadata.element_label,
                image: message.metadata.image,
              }))

            //if we go from onboarding to chat, we need to add the onboarding messages
            setMessages((prevMessages) => {
              if (lastOnboardingState === true && isOnboarding === false) {
                return [...prevMessages, ...messages]
              }

              return messages
            })

            // if there's an ongoing run, restart it
            if (data.run) {
              handleRequiresAction(data.run)
            }
          } catch (e) {
            console.log('error getting messages', e)
            setChatLoadErrorMessage(
              'Unable to load chat. Try refreshing the page. Contact support@landingsite.ai if the issue continues.'
            )
            setSubmitDisabled(true)
          }

          setSubmitDisabled(disableChatSubmit || false)
          setLoading(false)
        })()
      }

      if (!authLoading) {
        if (isOnboarding) {
          setLoading(false)
        } else if (isOnboarding === false) {
          setLoading(true)
          getMessages()
        }

        setLastOnboardingState(isOnboarding)
      }
    }, [isOnboarding, authLoading])

    useEffect(() => {
      onToggleChatPanel(isExpanded)
    }, [isExpanded])

    // automatically scroll to bottom of chat
    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const scrollToBottom = () => {
      // Add a small delay to ensure content is rendered
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 100)
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages])

    const sendAssistantMessage = async (text: string) => {
      await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          body: JSON.stringify({
            role: 'assistant',
            text,
          }),
        }
      )
    }

    const sendUserMessage = async (text: string) => {
      try {
        const response = await fetch(
          `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
            body: JSON.stringify({
              text,
              ...(clickedElement
                ? {
                    clickedCodeSectionHtml:
                      clickedElement.codeSectionElement.outerHTML,
                    clickedElementHtml: clickedElement.element.outerHTML,
                    elementLabel: truncate(
                      getElementLabel(clickedElement?.element),
                      512
                    ),
                    image: clickedElement?.imageSrc,
                  }
                : {}),
            }),
          }
        )

        const stream = AssistantStream.fromReadableStream(response.body)
        handleReadableStream(stream)
      } catch (e) {
        console.error('Error in sendUserMessage:', e)
        handleRunFailed()
      }
    }

    const submitActionResult = async (
      runId: string,
      toolCallOutputs: ToolCallOutput[]
    ) => {
      try {
        const response = await fetch(
          `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
            body: JSON.stringify({
              runId: runId,
              toolCallOutputs: toolCallOutputs,
            }),
          }
        )

        const stream = AssistantStream.fromReadableStream(response.body)
        handleReadableStream(stream)
      } catch (e) {
        console.log('error submitting action result', e)
        throw new Error('Error submitting action result')
      }
    }

    const resetChat = async () => {
      await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      )
      setMessages([])
    }
    const cancelRun = async () => {
      console.log('cancelRun')
      try {
        await fetch(
          `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        handleRunCompleted()
      } catch (e) {
        console.log(e)
        throw new Error('Error cancelling run')
      }
    }

    const handleSubmit = (messageOverride?: string) => {
      const waitForLoading = async () => {
        await loadPromiseRef.current
        const input = messageOverride || userInput
        if (!input.trim()) return
        sendUserMessage(input)
        addMessage({
          role: 'user',
          text: input,
          elementLabel:
            clickedElement && getElementLabel(clickedElement?.element),
          image: clickedElement?.imageSrc,
        })
        onChatUserMessageSubmitted && onChatUserMessageSubmitted(input)
        setUserInput('')
        setSubmitDisabled(true)
        scrollToBottom()
        setWaiting(true)
      }

      waitForLoading()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        if (submitDisabled) {
          return
        }

        handleSubmit()
      }
    }

    // textCreated - create new assistant message
    const handleTextCreated = (content: Text) => {
      console.log('handleTextCreated', content)
      // add an empty message to start appending to
      addMessage({
        role: 'assistant',
        text: '',
      })
    }

    // textDelta - append text to last assistant message
    const handleTextDelta = (delta: TextDelta, snapshot: Text) => {
      console.log(new Date().getTime(), 'handleTextDelta', delta, snapshot)
      if (delta.value != null) {
        appendToLastMessage(delta.value)
      } else if (snapshot.value != null) {
        replaceLastMessage(snapshot.value)
      }
    }

    // imageFileDone - show image in chat
    // TODO we don't have upload yet
    const handleImageFileDone = (image: ImageFile, snapshot: Message) => {
      console.log('handleImageFileDone', image, snapshot)
      appendToLastMessage(
        `\n![${image.file_id}](/api/files/${image.file_id})\n`
      )
    }

    // toolCallCreated - log new tool call
    const toolCallCreated = (toolCall: ToolCall) => {
      console.log('toolCallCreated', toolCall)
      // nothing to do
    }

    // toolCallDelta - log delta and snapshot for the tool call
    const toolCallDelta = (delta: ToolCallDelta, snapshot: ToolCall) => {
      // we don't need this, not rendering inline code interpreter responses
      console.log('toolCallDelta', JSON.stringify(delta, null, 2), JSON.stringify(snapshot, null, 2))
      if (delta.type != 'code_interpreter') return
      if (!delta.code_interpreter.input) return
      appendToLastMessage(delta.code_interpreter.input)
    }

    const functionCallHandler = async (
      toolCall: RequiredActionFunctionToolCall
    ) => {
      console.log('functionCallHandler', JSON.stringify(toolCall, null, 2))
      return functions[toolCall.function.name](toolCall.function.arguments)
    }

    // handleRequiresAction - handle function call
    type ToolCallOutput = { output: string; tool_call_id: string }
    const handleRequiresAction = async (run: Run) => {
      console.log('handleRequiresAction', JSON.stringify(run, null, 2))
      const thisRunId = run.id
      setWaiting(true)
      const toolCalls = run.required_action.submit_tool_outputs.tool_calls
      // loop over tool calls and call function handler
      const toolCallOutputs = await Promise.all(
        toolCalls.map(async (toolCall) => {
          const result = await functionCallHandler(toolCall)
          return { output: JSON.stringify(result), tool_call_id: toolCall.id }
        })
      )
      setSubmitDisabled(true)
      submitActionResult(thisRunId, toolCallOutputs)
    }

    // handleRunCompleted - re-enable the input form
    const handleRunCompleted = () => {
      setSubmitDisabled(false)
      setWaiting(false)
    }

    const handleRunFailed = () => {
      setChatLoadErrorMessage('An error occurred. Try refreshing the page.')
      setSubmitDisabled(false)
      setWaiting(false)
    }

    const handleReadableStream = (stream: AssistantStream) => {
      // messages
      stream.on('textCreated', handleTextCreated)
      stream.on('textDelta', handleTextDelta)

      // image
      stream.on('imageFileDone', handleImageFileDone)

      // code interpreter
      stream.on('toolCallCreated', toolCallCreated)
      stream.on('toolCallDelta', toolCallDelta)

      // events without helpers yet (e.g. requires_action and run.done)
      stream.on('event', (event) => {
        if (event.event === 'thread.run.requires_action') {
          handleRequiresAction(event.data)
        }
        if (event.event === 'thread.run.completed') {
          handleRunCompleted()
        }
        if (event.event === 'thread.run.failed') {
          handleRunFailed()
        }
      })
    }

    const appendToLastMessage = (text: string) => {
      console.log('appendToLastMessage', text)
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1]
        const updatedLastMessage = {
          ...lastMessage,
          text: lastMessage.text + text,
        }
        return [...prevMessages.slice(0, -1), updatedLastMessage]
      })
    }

    const replaceLastMessage = (text: string) => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1]
        const updatedLastMessage = {
          ...lastMessage,
          text: text,
        }
        return [...prevMessages.slice(0, -1), updatedLastMessage]
      })
    }

    const addMessage = ({
      role,
      text,
      elementLabel,
      image,
      colorPalette,
    }: TChatMessage) => {
      console.log('adding message', role, text)
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role,
          text,
          elementLabel,
          image,
          colorPalette,
          onColorPaletteChanged,
          onColorPaletteKeySaved,
        },
      ])
    }

    const handleGalleryClick = () => {
      console.log('handleGalleryClick')
      let galleryType = 'images'
      if (clickedElement && clickedElement.element) {
        galleryType =
          clickedElement.element.getAttribute(
            'data-landingsite-gallery-type'
          ) || 'images'
      }
      functions['openImageGallery'](galleryType)
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      console.log('handleFileChange', file)

      handleFileSelect(file)
    }

    const handleFileSelect = async (file: File) => {
      if (!file) {
        return
      }

      try {
        const uploadedFileResponse = await createUploadedFile({
          variables: {
            input: {
              fileName: file.name,
              size: file.size,
            },
          },
        })

        const uploadedFile = uploadedFileResponse.data.createUploadedFile

        const uploadResponse = await fetch(uploadedFile.uploadUrl, {
          method: 'PUT',
          body: file,
        })

        if (!uploadResponse.ok) {
          console.error(uploadResponse)
        }

        const newId = 'i' + Math.random().toString(36).substring(7) //shouldnt start with a number
        const media = {
          id: newId,
          src: 'upload',
          type: 'image',
          dataMedia: {},
          urls: { display: uploadedFile.s3Url },
        }

        onMediaUploaded(media as Media)
      } catch (e) {
        console.log('error uploading media', e)
      }
    }

    const setUpYourDomainButtonClicked = () => {
      onSetUpYourDomainButtonClicked && onSetUpYourDomainButtonClicked()
    }

    const ResetButton = () => {
      return (
        <button
          className="mb-10 ml-4 flex flex-row text-sm text-gray-500"
          onClick={resetChat}
        >
          <ArrowCounterClockwise className="block h-5 w-5 pr-2" />
          Restart Chat
        </button>
      )
    }

    return (
      <>
        <div
          className={clsx({
            'absolute bottom-0 z-10 w-full flex-none overflow-hidden lg:static lg:h-full lg:w-1/4 lg:min-w-[320px] lg:p-2 lg:pl-0':
              true,
            'h-fit': !isExpanded || isOnboarding,
            'h-3/4': isExpanded && !isOnboarding,
          })}
        >
          <div
            className={clsx(
              'relative flex h-full w-full flex-col pb-2 pt-2 lg:rounded',
              {
                'bg-white shadow-md': isExpanded,
                'bg-transparent': !isExpanded,
              }
            )}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
            />
            {isExpanded && (
              <>
                {!loading &&
                  isAuthenticated &&
                  !chatLoadErrorMessage &&
                  gettingStartedState &&
                  gettingStartedState !== 'done' && (
                    <div className="mt-2 px-4 py-1">
                      <div className="rounded-md border border-gray-300">
                        {!loading && gettingStartedState === 'edit' && (
                          <div className="space-y-1 p-4 text-center">
                            <div className="hidden lg:block">
                              <p className="text-xs uppercase text-gray-400">
                                Getting Started
                              </p>
                              <h2 className="text-xl font-medium text-gray-900">
                                Edit your website
                              </h2>
                            </div>
                            <div className="inline-flex flex-col space-y-2">
                              <button
                                onClick={upgradeButtonClicked}
                                className="text-sm text-gray-400 underline"
                              >
                                <span>Upgrade to launch your website</span>
                                <i className="fas fa-arrow-right ml-1 text-xs"></i>
                              </button>
                            </div>
                            <div className="flex items-center justify-center pt-1 text-xs sm:pt-2">
                              Email us! support@landingsite.ai
                            </div>
                          </div>
                        )}
                        {!loading && gettingStartedState === 'setupDomain' && (
                          <div className="space-y-1 p-4 text-center">
                            <p className="hidden text-xs uppercase text-gray-400 lg:block">
                              Getting Started
                            </p>
                            <h2 className="hidden text-lg font-medium text-gray-900 lg:block">
                              Link your domain
                            </h2>
                            <p className="text-sm text-gray-500">
                              Your domain hasn't been verified yet.
                            </p>
                            <div className="inline-flex flex-col space-y-2">
                              <button
                                onClick={setUpYourDomainButtonClicked}
                                className="text-sm text-gray-400 underline"
                              >
                                <span>
                                  See Domain Verification Instructions
                                </span>
                                <i className="fas fa-arrow-right ml-1 text-xs"></i>
                              </button>
                            </div>
                          </div>
                        )}
                        {!loading && gettingStartedState === 'upgrade' && (
                          <div className="space-y-1 p-4 text-center">
                            <p className="hidden text-xs uppercase text-gray-400 lg:block">
                              Getting Started
                            </p>
                            <h2 className="hidden text-lg font-medium text-gray-900 lg:block">
                              Upgrade to Launch
                            </h2>
                            <p className="text-sm text-gray-500">
                              Upgrade to the Pro plan to launch your website.
                            </p>
                            <div className="inline-flex flex-col space-y-2">
                              <button
                                onClick={upgradeButtonClicked}
                                className="text-sm text-gray-400 underline"
                              >
                                <span>Go To Checkout</span>
                                <i className="fas fa-arrow-right ml-1 text-xs"></i>
                              </button>
                            </div>
                          </div>
                        )}
                        {!loading && gettingStartedState === 'publish' && (
                          <div className="space-y-1 p-4 text-center">
                            <p className="hidden text-xs uppercase text-gray-400 lg:block">
                              Getting Started
                            </p>
                            <h2 className="hidden text-lg font-medium text-gray-900 lg:block">
                              Launch your website!
                            </h2>
                            <p className="text-sm text-gray-500">
                              You're ready to publish your website to the world.
                            </p>
                            <div className="inline-flex flex-col space-y-2">
                              <button
                                onClick={launchWebsiteButtonClicked}
                                className="text-sm text-gray-400 underline"
                              >
                                <span>Launch Website</span>
                                <i className="fas fa-arrow-right ml-1 text-xs"></i>
                              </button>
                            </div>
                          </div>
                        )}
                        {!loading && gettingStartedState === 'publishing' && (
                          <div className="space-y-1 p-4 text-center">
                            <p className="hidden text-xs uppercase text-gray-400 lg:block">
                              Getting Started
                            </p>
                            <h2 className="text-lg font-medium text-gray-900">
                              Launching...
                            </h2>
                            <div className="pt-2">
                              <i className="fas fa-spinner fa-spin text-gray-500"></i>
                            </div>
                          </div>
                        )}
                        {!loading && gettingStartedState === 'published' && (
                          <div className="space-y-1 p-4 text-center">
                            <button
                              className="absolute right-5 top-5 text-gray-300"
                              onClick={() => setShouldShowPublishedModal(false)}
                            >
                              <i className="fa-regular fa-xmark h-3 w-3"></i>
                            </button>
                            <h2 className="text-lg font-medium text-gray-900">
                              Hooray! Your website is live for the world to see.
                            </h2>
                            <div className="">
                              <a
                                className="text-blue-500 underline"
                                href={`https://${userDomain}/`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >{`https://${userDomain}/`}</a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                <div className="my-2 flex-grow overflow-y-auto">
                  {loading && (
                    <div className="flex flex-col gap-4 px-4 py-1">
                      <ChatMessage waiting={true} customRole={'assistant'} />
                    </div>
                  )}
                  {!loading && chatLoadErrorMessage && !isOnboarding && (
                    <div className="flex flex-col gap-4 px-4 py-1">
                      <ChatMessage
                        customRole={'assistant'}
                        text={chatLoadErrorMessage}
                      />
                      <ResetButton />
                    </div>
                  )}
                  {!loading &&
                    !chatLoadErrorMessage &&
                    !isOnboarding &&
                    messages.length === 0 && (
                      <div className="flex flex-col gap-4 px-4 py-1">
                        <ChatMessage
                          customRole={'assistant'}
                          text="Click on anything on your website to change it."
                        />
                      </div>
                    )}
                  {messages.map((message, index) => (
                    <div className="flex flex-col gap-4 px-4 py-1" key={index}>
                      <ChatMessage
                        customRole={message.role}
                        text={message.text}
                        elementLabel={message.elementLabel}
                        image={message.image}
                        colorPalette={colorPalette}
                        onColorPaletteChanged={onColorPaletteChanged}
                        onColorPaletteKeySaved={onColorPaletteKeySaved}
                      />
                    </div>
                  ))}
                  {waiting && (
                    <div className="flex flex-col gap-4 px-2 py-1">
                      <ChatMessage
                        customRole="assistant"
                        waiting={true}
                        chatWaitingText={chatWaitingText}
                        onCancelRun={cancelRun}
                      />
                    </div>
                  )}
                  {messages.length !== 0 && !isOnboarding && <ResetButton />}
                  <div ref={messagesEndRef} />
                </div>

                {false && clickedElement && (
                  <>
                    <hr />
                    <div className="relative m-2 flex items-start rounded-xl border bg-gray-50 p-2">
                      <div className="me-4">
                        <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                          {!clickedElement.imageSrc &&
                            truncate(getElementLabel(clickedElement.element))}
                          {clickedElement.imageSrc && (
                            <img
                              src={clickedElement.imageSrc}
                              alt="selected element"
                              className="h-20"
                            />
                          )}
                        </span>
                        <button
                          className="absolute right-2 top-2 text-black"
                          onClick={handleClearClickedElement}
                        >
                          <X className="h-3 w-3" />
                        </button>
                        <span className="flex gap-1 text-xs font-normal text-gray-500">
                          <CrosshairSimple className="inline-block h-4 w-4" />
                          selected element
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="relative mx-2 flex items-center">
              <div
                className={clsx('flex-grow rounded-xl shadow-sm', {
                  'bg-white': !isExpanded,
                })}
              >
                <div
                  className={clsx({
                    'relative rounded-t-xl border-blue-500 px-2 py-1': true,
                    'rounded-xl': !clickedElement,
                    'border-2': !clickedElement,
                    'border-x-2 border-t-2': clickedElement,
                    'bg-gray-200': submitDisabled,
                    hidden: isOnboarding,
                    'bg-white': !isExpanded,
                    'shadow-xl': !isExpanded && !clickedElement,
                  })}
                >
                  <textarea
                    className="block min-h-full w-full resize-none scroll-p-5 overflow-x-auto rounded-t-xl px-2 py-2 text-sm leading-6 transition duration-100 placeholder:text-neutral-400 focus:outline-none disabled:bg-gray-200"
                    placeholder={
                      submitDisabled ? '' : 'What would you like to change...'
                    }
                    disabled={submitDisabled}
                    rows={1}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsExpanded(true)}
                  ></textarea>
                  <button
                    className="absolute right-2.5 top-1/2 m-0 h-4 w-4 -translate-y-1/2 disabled:opacity-50"
                    disabled={submitDisabled}
                  >
                    <ArrowRight className="block h-4 w-4 align-middle" />
                  </button>
                  {clickedElement && clickedElement.imageSrc && (
                    <>
                      <button
                        className="m-1 rounded border border-gray-300 bg-white px-2 py-2 text-xs first:ml-0 last:mr-0 hover:bg-gray-100 2xl:px-4 2xl:text-sm"
                        onClick={handleGalleryClick}
                      >
                        <i className="far fa-images mr-2"></i>
                        <span>Gallery</span>
                      </button>
                      <button
                        className="m-1 rounded border border-gray-300 bg-white px-2 py-2 text-xs first:ml-0 last:mr-0 hover:bg-gray-100 2xl:px-4 2xl:text-sm"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <i className="far fa-upload mr-2"></i>
                        <span>Upload</span>
                      </button>
                      <button
                        className="m-1 rounded border border-gray-300 bg-white px-2 py-2 text-xs first:ml-0 last:mr-0 hover:bg-gray-100 2xl:px-4 2xl:text-sm"
                        onClick={() => onSwapImages?.()}
                      >
                        <i className="far fa-exchange-alt mr-2"></i>
                        <span>Swap</span>
                      </button>
                    </>
                  )}
                </div>
                {clickedElement && (
                  <div className="flex items-center justify-between rounded-b-xl border-2 border-t border-blue-500 bg-blue-50 px-4 py-3">
                    <div className="flex w-full items-center gap-3">
                      <div className="relative mr-20 flex items-start rounded-xl border bg-gray-50 p-2">
                        <div className="me-4">
                          <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                            {!clickedElement.imageSrc &&
                              truncate(getElementLabel(clickedElement.element))}
                            {clickedElement.imageSrc && (
                              <img
                                src={clickedElement.imageSrc}
                                alt="selected element"
                                className="h-20"
                              />
                            )}
                          </span>
                          <button
                            className="absolute right-2 top-2 text-black"
                            onClick={handleClearClickedElement}
                          >
                            <X className="h-3 w-3" />
                          </button>
                          <span className="flex gap-1 text-xs font-normal text-gray-500">
                            <CrosshairSimple className="inline-block h-4 w-4" />
                            selected element
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)

const ChatMessage = ({
  customRole,
  text,
  elementLabel,
  image,
  waiting,
  chatWaitingText,
  onCancelRun,
  actions,
  colorPalette,
  onColorPaletteChanged,
  onColorPaletteKeySaved,
}: {
  customRole: string
  text?: string
  elementLabel?: string
  image?: string
  waiting?: boolean
  chatWaitingText?: string
  onCancelRun?: () => void
  actions?: { label: string; onClick: () => void }[]
  colorPalette?: Prisma.JsonValue
  onColorPaletteChanged?: (colorPalette: Prisma.JsonValue) => void
  onColorPaletteKeySaved?: (key: string, value: string) => void
}) => {
  const [markdown, setMarkdown] = useRemark()
  const [originalColorPaletteValues] = useState(colorPalette)

  const [colorPaletteCopy, setColorPaletteCopy] = useState(colorPalette)
  const [hasChanged, setHasChanged] = useState({})

  const { logIn, signUp } = useAuth()

  useEffect(() => {
    // the selected element is in the text like <!-- begin selected element html --> <div></div> <!-- end selected element html -->
    // we want to remove that from the text
    const updatedText =
      text &&
      text.replace(
        /<!-- begin selected element html -->.*<!-- end selected element html -->/s,
        ''
      )
    setMarkdown(updatedText)
  }, [text])

  useEffect(() => {
    onColorPaletteChanged && onColorPaletteChanged(colorPaletteCopy)
  }, [colorPaletteCopy])

  const onLoginClick = () => {
    logIn({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const onSignupClick = () => {
    signUp({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  if (waiting) {
    return (
      <div className="leading-1.5 prose group relative mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4 text-sm text-gray-900">
        <div className="justify-left flex items-center space-x-2">
          {!chatWaitingText && (
            <>
              <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500 [animation-delay:-0.3s]"></div>
              <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500 [animation-delay:-0.15s]"></div>
              <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500"></div>
            </>
          )}
          {chatWaitingText && (
            <span className="animate-pulse text-xs text-gray-500">
              {chatWaitingText}
            </span>
          )}
        </div>
        {onCancelRun && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-gray-500 text-white opacity-0 transition-opacity duration-200 hover:bg-gray-900 group-hover:opacity-100"
            onClick={onCancelRun}
            title="Stop"
          >
            <div className="flex h-5 w-5 items-center justify-center">
              <div className="h-2 w-2 rounded-sm bg-white"></div>
            </div>
          </button>
        )}
      </div>
    )
  }

  if (customRole === 'user') {
    return (
      <div className="leading-1.5 prose ml-5 flex flex-col whitespace-pre-wrap rounded-s-xl rounded-ee-xl border-gray-200 bg-blue-100 px-4 py-2.5 text-sm text-gray-900">
        {markdown}
        {(elementLabel || image) && (
          <div className="my-2.5 flex items-start rounded-xl bg-gray-50 p-2">
            <div className="me-2">
              <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                {elementLabel && !image && truncate(elementLabel)}
                {image && (
                  <img src={image} alt="selected element" className="h-20" />
                )}
              </span>
              <span className="flex gap-1 text-xs font-normal text-gray-500">
                <CrosshairSimple className="inline-block h-4 w-4" />
                selected element
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  if (customRole === 'assistant') {
    return (
      <div className="flex flex-col">
        <div className="leading-1.5 prose mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
          <div className="flex items-start">
            {waiting && (
              <button className="mr-2 flex-shrink-0" onClick={onCancelRun}>
                <CircleNotch className="h-4 w-4 animate-spin" />
              </button>
            )}
            <div>{markdown}</div>
          </div>
        </div>
        {actions && actions.length > 0 && (
          <div className="mt-2 flex flex-col items-end space-y-2">
            {actions.map((action, index) => (
              <button
                className="rounded border border-gray-200 bg-white px-3 py-1 text-sm text-gray-700 hover:bg-gray-50"
                key={index}
                onClick={action.onClick}
              >
                {action.label}
              </button>
            ))}
          </div>
        )}
      </div>
    )
  }

  if (customRole === 'system') {
    if (text === '<loginComponent>') {
      return (
        <div className="leading-1.5 flex flex-col rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
          <div className="flex space-x-1">
            <button
              onClick={onLoginClick}
              className="flex-1 rounded bg-white px-2 py-1 text-sm text-gray-800"
            >
              Login
            </button>
            <button
              onClick={onSignupClick}
              className="flex-1 rounded bg-blue-500 px-2 py-1 text-sm text-white"
            >
              Sign Up
            </button>
          </div>
        </div>
      )
    }

    if (text === '<colorPalettePicker>') {
      console.log('color palette:', colorPaletteCopy)
      if (!colorPaletteCopy) {
        return
      }

      const sortedKeys = [
        ...Object.keys(colorPaletteCopy).filter((key) =>
          key.startsWith('primary-')
        ),
        ...Object.keys(colorPaletteCopy).filter((key) =>
          key.startsWith('secondary-')
        ),
        ...Object.keys(colorPaletteCopy).filter(
          (key) =>
            !key.startsWith('primary-') &&
            !key.startsWith('secondary-') &&
            key.includes('color')
        ),
      ]
      return (
        <div className="leading-1.5 prose mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
          <div className="mb-2 font-semibold">
            Change your website's color palette:
          </div>
          <div className="flex flex-col space-y-3">
            {sortedKeys.map((key) => {
              const isColor = key.includes('color')
              const isFont = key.includes('font')
              return (
                <div
                  key={key}
                  className="flex items-center justify-between rounded-lg bg-gray-100 px-2"
                >
                  {isColor ? (
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <input
                          type="color"
                          className="h-8 w-10 cursor-pointer border-none"
                          value={colorPaletteCopy[key]}
                          onInput={(e) => {
                            setColorPaletteCopy((prev) => ({
                              ...(typeof prev === 'object' ? prev : {}),
                              [key]: e.target.value,
                            }))

                            setHasChanged((prev) => ({
                              ...prev,
                              [key]: true,
                            }))
                          }}
                          onChange={() => {}}
                        />
                        <label className="ml-2 flex-1 whitespace-nowrap text-sm font-bold text-gray-700">
                          {key
                            .replace('bg', '')
                            .replace(/-/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                        </label>
                      </div>
                      {hasChanged[key] && (
                        <div>
                          <button
                            className="mr-2 text-xs text-blue-500"
                            onClick={() => {
                              setColorPaletteCopy((prev) => {
                                setHasChanged((prev) => ({
                                  ...prev,
                                  [key]: false,
                                }))

                                return {
                                  ...(typeof prev === 'object' ? prev : {}),

                                  [key]: originalColorPaletteValues[key],
                                }
                              })
                            }}
                          >
                            Revert
                          </button>
                          <button
                            className="text-xs text-blue-500"
                            onClick={() => {
                              onColorPaletteKeySaved &&
                                onColorPaletteKeySaved(
                                  key,
                                  colorPaletteCopy[key]
                                )

                              setHasChanged((prev) => ({
                                ...prev,
                                [key]: false,
                              }))
                            }}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {isFont ? (
                        <>
                          <label className="mr-2 flex-1 whitespace-nowrap text-sm font-bold text-gray-700">
                            {key
                              .replace(/-/g, ' ')
                              .replace(/\b\w/g, (char) => char.toUpperCase())}
                          </label>
                          <input
                            type="text"
                            value={colorPaletteCopy[key]}
                            className="p-1"
                            onChange={() => {}}
                          />
                        </>
                      ) : (
                        <>
                          <label className="mr-2 flex-1 whitespace-nowrap text-sm font-bold text-gray-700">
                            {key
                              .replace(/-/g, ' ')
                              .replace(/\b\w/g, (char) => char.toUpperCase())}
                          </label>
                          <input
                            type="text"
                            value={colorPaletteCopy[key]}
                            className="p-1"
                            onChange={() => {}}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  return null
}

export default ChatPanel

// overriding truncate from 'src/lib/formatters' to make it shorter
const truncate = (value: string, max = 75) => {
  if (max < 3) {
    max = 3
  }

  let output = value?.toString() ?? ''

  if (output.length > max) {
    output = output.substring(0, max - 3) + '...'
  }

  return output
}

const getElementLabel = (element: HTMLElement) => {
  return element?.innerText || element?.tagName
}
