// same as api/src/lib/fonts.ts
export const buildFontUrl = (fonts?: string[]) => {
  if (!fonts || fonts.length === 0) {
    fonts = defaultFonts
  }

  const fontFamilies = fonts.map((font) => {
    // Encode the font name for URL
    const encodedFont = font.replace(/\s+/g, '+')
    // Add weight variants
    return `family=${encodedFont}:wght@300;400;500;600;700`
  })

  return `https://fonts.googleapis.com/css2?${fontFamilies.join(
    '&'
  )}&display=swap`
}

export const defaultFonts = ['Poppins', 'IBM Plex Sans', 'Heebo', 'Arimo']
