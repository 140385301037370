import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { Prisma } from '@prisma/client'
import type { Website } from 'types/graphql'

import { useAuth } from 'src/auth'

import PageFrame, {
  CodeSection,
  FrameElementEvent,
  PageFrameHandler,
  PageWithCodeSections,
} from '../PageFrame/PageFrame'

type WebsitePanelProps = {
  website: Website
  activePage: PageWithCodeSections
  websiteWidth: 'mobile' | 'tablet' | 'laptop' | 'desktop'
  navigateToPanel: (panel: string, tabName?: string) => void
  navigateToWebsitePage: (pagePath: string) => void
  onFrameElementClick: (event: FrameElementEvent) => void
  onAddNewCodeSectionAbove: (id: string) => void
  onAddNewCodeSectionBelow: (id: string) => void
  onMoveClickedCodeSectionUp: (id: string) => void
  onMoveClickedCodeSectionDown: (id: string) => void
  onDeleteClickedCodeSection: (id: string) => void
  onPageChange: (params: {
    page: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }) => void
  onPageFrameLinkClick: (path: string) => void
  onPageFrameGeneratePageClick: (pageId: string) => void
  colorPalette: Prisma.JsonValue
}

export type WebsitePanelHandler = {
  getUpdatedPage: () => {
    updatedPage: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }
  clearClickedElement: () => void
  setClickedElement: (element: HTMLElement) => void
  getDocument: () => Document
}

const WebsitePanel = forwardRef(
  (
    {
      website,
      activePage,
      websiteWidth,
      navigateToPanel,
      navigateToWebsitePage,
      onFrameElementClick,
      onAddNewCodeSectionAbove,
      onAddNewCodeSectionBelow,
      onMoveClickedCodeSectionUp,
      onMoveClickedCodeSectionDown,
      onDeleteClickedCodeSection,
      onPageChange,
      onPageFrameLinkClick,
      onPageFrameGeneratePageClick,
      colorPalette,
    }: WebsitePanelProps,
    ref
  ) => {
    // give the parent a hook into the panel
    useImperativeHandle<unknown, WebsitePanelHandler>(ref, () => {
      return {
        getUpdatedPage: () => pageFrameRef.current?.getUpdatedPage(),
        clearClickedElement: clearClickedElement,
        setClickedElement: setClickedElement,
        getDocument: () => pageFrameRef.current?.getContentWindow().document,
      }
    })

    const { isAuthenticated, loading } = useAuth()

    const pageFrameRef = useRef<PageFrameHandler>(null)
    const pageFrameParentRef = useRef<HTMLDivElement>(null)

    const initialWidth =
      websiteWidth === 'desktop'
        ? '1920px'
        : websiteWidth === 'laptop'
        ? '1280px'
        : websiteWidth === 'tablet'
        ? '768px'
        : '375px'

    const [websiteWidthPx, setWebsiteWidthPx] = useState(initialWidth)
    const [websiteScale, setWebsiteScale] = useState(1)
    const [websiteOrigin, setWebsiteOrigin] = useState('origin-top')
    const [offsetLeft, setOffsetLeft] = useState(0)
    const [isInEditMode, setIsInEditMode] = useState(true)

    useEffect(() => {
      console.log('isInEditMode:', isInEditMode)
    }, [isInEditMode])

    useEffect(() => {
      if (!loading) {
        setIsInEditMode(isAuthenticated)
      }
    }, [loading, isAuthenticated])

    useEffect(() => {
      changeViewportWidth(websiteWidth)
    }, [websiteWidth])

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === pageFrameParentRef.current) {
            changeViewportWidth(websiteWidth)
          }
        }
      })

      if (pageFrameParentRef.current) {
        resizeObserver.observe(pageFrameParentRef.current)
      }

      // Clean up observer on component unmount
      return () => {
        resizeObserver.disconnect()
      }
    }, [websiteWidth])

    const adjustHeight = () => {
      if (pageFrameRef.current) {
        pageFrameRef.current.adjustHeight()
      }
    }

    const clearClickedElement = () => {
      if (pageFrameRef.current) {
        console.log('clearing clicked element in website panel')
        pageFrameRef.current.clearClickedElement()
      }
    }

    const setClickedElement = (element: HTMLElement) => {
      if (pageFrameRef.current) {
        pageFrameRef.current.setClickedElement(element)
      }
    }

    const onElementClick = (event: FrameElementEvent) => {
      onFrameElementClick(event)
    }

    const changeViewportWidth = (
      size: 'mobile' | 'tablet' | 'laptop' | 'desktop'
    ) => {
      const widthMap = {
        mobile: 375,
        tablet: 768,
        laptop: 1280,
        desktop: 1920,
      }

      const setViewport = (width: number) => {
        setWebsiteWidthPx(`${width}px`)
        adjustHeight()
        setOffsetLeft(0)

        if (!pageFrameParentRef.current) {
          return
        }

        if (pageFrameParentRef.current.offsetWidth < width) {
          setWebsiteScale(pageFrameParentRef.current.offsetWidth / width)
          setWebsiteOrigin('origin-top-left')
        } else {
          setWebsiteScale(1)
          setOffsetLeft((pageFrameParentRef.current.offsetWidth - width) / 2)
        }
      }

      setViewport(widthMap[size])
    }

    if (
      website.stripePlan === 'Free' &&
      activePage &&
      activePage.path !== 'home'
    ) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <div className="text-center">
            <h1 className="text-2xl font-semibold text-gray-800">
              You need to upgrade to generate this page
            </h1>
            <button
              type="button"
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => navigateToPanel('settings', 'plan')}
            >
              Upgrade
            </button>
            <div>
              <button
                className="mt-4 text-blue-500 underline"
                onClick={() => navigateToWebsitePage('home')}
              >
                Go back to home page
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div
        ref={pageFrameParentRef}
        className="relative h-full w-full overflow-x-hidden overflow-y-scroll"
      >
        <PageFrame
          ref={pageFrameRef}
          className={`absolute left-[${offsetLeft}px] top-0 w-[${websiteWidthPx}] scale-[${websiteScale}] ${websiteOrigin} h-full bg-white`}
          page={activePage}
          headerCodeSection={website.headerCodeSection as CodeSection}
          footerCodeSection={website.footerCodeSection as CodeSection}
          fonts={website.fonts}
          onElementClick={onElementClick}
          onAddNewCodeSectionAbove={onAddNewCodeSectionAbove}
          onAddNewCodeSectionBelow={onAddNewCodeSectionBelow}
          onMoveClickedCodeSectionUp={onMoveClickedCodeSectionUp}
          onMoveClickedCodeSectionDown={onMoveClickedCodeSectionDown}
          onDeleteClickedCodeSection={onDeleteClickedCodeSection}
          isNewChatPageFrame={true}
          // onElementHover={onFrameElementHover}
          onPageChange={onPageChange}
          isInEditMode={isInEditMode}
          onPageFrameLinkClick={onPageFrameLinkClick}
          onPageFrameGeneratePageClick={onPageFrameGeneratePageClick}
          colorPalette={colorPalette}
        ></PageFrame>
      </div>
    )
  }
)

export default WebsitePanel
