import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useState,
} from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import type { Website } from 'types/graphql'

import CustomDomainSettings from './Settings/CustomDomainSettings'
import DeleteSettings from './Settings/DeleteSettings'
import GeneralSettings from './Settings/GeneralSettings'
import PlanSettings from './Settings/PlanSettings'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/Tabs'

export type SettingsPanelHandler = {
  changeTab: (tab: string) => void
}

type SettingsPanelProps = {
  website: Website
  initialTab: string
  onClose: () => void
}

const SettingsPanel = forwardRef(
  ({ website, initialTab, onClose }: SettingsPanelProps, ref) => {
    useImperativeHandle(ref, () => ({
      changeTab(tab: string) {
        setCurrentTab(tab.toLowerCase())
      },
    }))

    const [currentTab, setCurrentTab] = useState(
      initialTab.toLowerCase() || 'general'
    )

    const [
      isShowingEmailVerificationModal,
      setIsShowingEmailVerificationModal,
    ] = useState(false)

    return (
      <>
        <div className="relative mx-auto h-full w-full overflow-scroll bg-white px-4 py-4">
          <div className="absolute right-0 top-12 block pr-4 pt-4 sm:top-0">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <Tabs
            value={currentTab}
            onValueChange={setCurrentTab}
            className="w-full"
          >
            <TabsList className="mb-4">
              <TabsTrigger value="general">General</TabsTrigger>
              <TabsTrigger value="plan">Plan</TabsTrigger>
              <TabsTrigger value="custom domain">Custom Domain</TabsTrigger>
              <TabsTrigger value="delete website">Delete Website</TabsTrigger>
            </TabsList>

            <TabsContent value="general">
              <GeneralSettings website={website} />
            </TabsContent>

            <TabsContent value="plan">
              <PlanSettings website={website} />
            </TabsContent>

            <TabsContent value="custom domain">
              <CustomDomainSettings
                websiteId={website.id}
                stripePlan={website.stripePlan}
                onUpgradeClicked={() => setCurrentTab('plan')}
              />
            </TabsContent>

            <TabsContent value="delete website">
              <DeleteSettings website={website} />
            </TabsContent>
          </Tabs>
        </div>

        <Transition appear show={isShowingEmailVerificationModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[100]"
            onClose={() => {
              setIsShowingEmailVerificationModal(false)
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h2"
                      className="text-center text-2xl font-medium leading-6 text-gray-900"
                    >
                      You Must Verify Your Email Before Upgrading
                    </Dialog.Title>
                    <div className="mt-12 text-center text-xl">
                      Check your email for a verification link then try again
                    </div>
                    <div className="mt-4 text-center text-sm">
                      Email us at support@landingsite.ai if you have any issues
                    </div>
                    <div className="mt-8 flex items-center justify-center">
                      <button
                        className="rounded-md border border-gray-300 px-4 py-2 text-gray-900 hover:bg-gray-200"
                        onClick={() => {
                          setIsShowingEmailVerificationModal(false)
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }
)

export default SettingsPanel
