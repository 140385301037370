import * as cheerio from 'cheerio'
import { Page, Website } from 'types/graphql'

import { CodeSection } from 'src/components/PageFrame/PageFrame'

export type GalleryType = 'images' | 'videos' | 'illustrations' | 'animations'

export type Media = {
  id: string
  src: string
  type: string
  dataMedia: string
  urls: {
    regular: string
    display: string
    small: {
      image: string
      video: string
    }
  }
  user: {
    name: string
    username: string
  }
}
type SearchImagesOptions = {
  token: string
  websiteId?: string
  keyword: string
  page?: number
  perPage?: number
  galleryType?: GalleryType
  galleryShouldShowFreeImages?: boolean
  galleryShouldShowProImages?: boolean
}

export type SearchImagesResponseType = {
  total: number
  results: Media[]
}

export async function searchImages({
  token,
  websiteId,
  keyword,
  page,
  perPage,
  galleryType = 'images',
  galleryShouldShowFreeImages = true,
  galleryShouldShowProImages = true,
}: SearchImagesOptions): Promise<null | SearchImagesResponseType> {
  let response: Response

  const fetchOptions: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      websiteId,
      keyword,
      page,
      perPage,
      galleryType,
      galleryShouldShowFreeImages,
      galleryShouldShowProImages,
    }),
  }

  if (token) {
    fetchOptions.headers = {
      'Content-Type': 'application/json',
      'auth-provider': 'auth0',
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    response = await fetch(
      `${process.env.API_BASE_URL}/getImagesFromKeyword`,
      fetchOptions
    )
  } catch (error) {
    console.log(error)
    return
  }

  if (!response.ok) {
    console.log('error:', response)
    return
  }

  const res = await response.json()
  return res.data
}

export async function searchIllustrations({
  token,
  websiteId,
  keyword,
  page,
  perPage,
  galleryType = 'illustrations',
}: SearchImagesOptions): Promise<null | SearchImagesResponseType> {
  let response: Response

  const fetchOptions: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      websiteId,
      keyword,
      page,
      perPage,
      galleryType,
    }),
  }

  if (token) {
    fetchOptions.headers = {
      'Content-Type': 'application/json',
      'auth-provider': 'auth0',
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    response = await fetch(
      `${process.env.API_BASE_URL}/getImagesFromKeyword`,
      fetchOptions
    )
  } catch (error) {
    console.log(error)
    return
  }

  if (!response.ok) {
    console.log('error:', response)
    return
  }

  const res = await response.json()
  return res.data
}

export const getMediaFromPage = async ({
  page,
  website,
}: {
  page: Page
  website: Website
}) => {
  //go through each page.codeSection and see if it has any images or videos
  //if it does, then we need to add it to the media array
  const mediaArray: { id: string; src: string; type: string }[] = []

  const codeSections: CodeSection[] = []

  if (website.headerCodeSection) {
    codeSections.push(website.headerCodeSection as CodeSection)
  }

  codeSections.push(...(page.codeSections as CodeSection[]))

  if (website.footerCodeSection) {
    codeSections.push(website.footerCodeSection as CodeSection)
  }

  for (const codeSection of codeSections) {
    const $ = cheerio.load(codeSection.html)

    const elems = $('img, div, video')
      .toArray()
      .map(async (elem) => {
        const dataImgAttr = $(elem).attr('data-media')
        if (dataImgAttr) {
          try {
            // parse the JSON string into a JavaScript object
            const { src, id, type } = JSON.parse(dataImgAttr)

            if (!src || !id || !type) {
              return
            }

            if (mediaArray.find((v) => v.id === id)) {
              return
            }

            mediaArray.push({ id, src, type })
          } catch (e) {
            console.log('error parsing json for dataImgAttr')
            console.log(elem)
            console.log(dataImgAttr)
            console.log(e)
          }
        }
      })

    await Promise.all(elems)
  }

  return mediaArray
}

export const updateElementImage = ({
  element,
  src,
  dataMedia,
  backgroundImage,
}: {
  element: HTMLElement
  src?: string
  dataMedia?: string
  backgroundImage?: string
}) => {
  if (src) {
    element.setAttribute('src', src)
  }
  if (dataMedia) {
    element.setAttribute('data-media', dataMedia)
  }
  if (backgroundImage) {
    element.style.backgroundImage = backgroundImage
  }
  if (!element.classList.contains('dont-replace')) {
    element.classList.add('dont-replace')
  }

  const match = element.className.match(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/)
  if (match) {
    const updatedClassList = element.className
      .replace(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/, '')
      .trim()
    element.setAttribute('class', updatedClassList + " bg-[url('" + src + "')]")
  }

  element.setAttribute('oncontextmenu', 'return false;')
}

const updateSameImages = ({
  document,
  sourceElement,
}: {
  document: Document
  sourceElement: HTMLImageElement | HTMLDivElement
}) => {
  if (sourceElement.classList.contains('same-image')) {
    Array.from(document.getElementsByClassName('same-image')).forEach(
      (el: HTMLElement) => {
        updateElementImage({
          element: el,
          src: sourceElement.getAttribute('src'),
          dataMedia: sourceElement.getAttribute('data-media'),
          backgroundImage: sourceElement.style.backgroundImage,
        })
      }
    )
  }
}

export const getUpdatedSeoImageTags = ({
  element,
  activePage,
  newImageUrl,
}: {
  element: HTMLImageElement
  activePage: Page
  newImageUrl: string
}) => {
  const isSeoImage =
    element.classList.contains('seo-image') ||
    element.getAttribute('data-seo-image') !== null

  if (isSeoImage && activePage.seoMetaTags) {
    const $ = cheerio.load(activePage.seoMetaTags)
    //find meta tag where name is twitter:image or property is og:image, then update the content
    $('meta').each(function () {
      const name = $(this).attr('name')
      const property = $(this).attr('property')

      if (name === 'twitter:image' || property === 'og:image') {
        $(this).attr('content', newImageUrl)
      }
    })

    return $('head').html()
  }
}

export const replaceClickedElementWithMedia = ({
  element,
  media,
  document,
}: {
  element: HTMLElement
  media: Media
  document: Document
}) => {
  if (!media) {
    return
  }

  const originalElement = element.cloneNode(true) as HTMLElement
  const parentElement = element.parentNode

  if (media.type === 'image' || media.type === 'illustration') {
    if (
      element.tagName.toLowerCase() === 'img' ||
      element.className.match(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/)
    ) {
      updateElementImage({
        element: element as HTMLImageElement,
        src: media.urls.display,
        dataMedia: media.dataMedia,
      })
    } else if (
      element.style.backgroundImage ||
      element.tagName.toLowerCase() === 'video'
    ) {
      // create new element, since the one they clicked isn't an image
      const newElement = document.createElement('div')
      // copy the classes from the original element
      newElement.className = originalElement.className
      updateElementImage({
        element: newElement,
        dataMedia: media.dataMedia,
        backgroundImage: `url("${media.urls.display}")`,
      })
      parentElement.replaceChild(newElement, element)
      element = newElement
    }

    updateSameImages({
      sourceElement: element as HTMLImageElement,
      document,
    })
  } else if (media.type === 'video' || media.type === 'lottie') {
    const newElement = document.createElement('video')
    newElement.setAttribute('src', media.urls.small.video)
    newElement.setAttribute('autoplay', '')
    newElement.setAttribute('loop', '')
    newElement.setAttribute('muted', '')
    newElement.setAttribute('playsinline', '')
    newElement.setAttribute('oncontextmenu', 'return false;')
    newElement.className = originalElement.className

    newElement.setAttribute('poster', media.urls.small.image)
    newElement.setAttribute('data-media', media.dataMedia)

    if (!newElement.classList.contains('dont-replace')) {
      newElement.classList.add('dont-replace')
    }

    parentElement.replaceChild(newElement, element)
    element = newElement
  }
  return element
}
