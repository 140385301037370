import { Fragment, useState, useEffect, useMemo } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { Prisma } from '@prisma/client'
import { Font } from '@samuelmeuli/font-manager'
import { Website } from 'types/graphql'

import { PageWithCodeSections } from '../PageFrame/PageFrame'

import FontPicker from './FontPicker'

const Tooltip = ({ text }: { text: string }) => {
  return (
    <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
      {text}
    </span>
  )
}
type ToolbarProps = {
  website: Website
  colorPalette: Prisma.JsonValue
  activePage?: PageWithCodeSections
  onViewportWidthChange?: (device: string) => void
  onNewPageClicked?: () => void
  onDeletePageClicked?: (page: PageWithCodeSections) => void
  changeActivePage?: (page: PageWithCodeSections) => void
  onUndoClick?: () => void
  onRedoClick?: () => void
  onSaveClick?: () => void
  onColorChangeClick?: () => void
  isWebsiteSaving?: boolean
  didSaveError?: boolean
  undoDisabled?: boolean
  redoDisabled?: boolean
  element?: HTMLElement
  onFontChange: (font: Font, element: HTMLElement) => void
  onFontWeightChange: (weight: string, element: HTMLElement) => void
  onFontStyleChange: (
    style: 'bold' | 'italic' | 'underline',
    element: HTMLElement
  ) => void
  onLinkChange: (href: string | null, element: HTMLElement) => void
}
const PageToolbar = ({
  website,
  colorPalette,
  activePage,
  onViewportWidthChange,
  onNewPageClicked,
  onDeletePageClicked,
  changeActivePage,
  onUndoClick,
  onRedoClick,
  onSaveClick,
  onColorChangeClick,
  isWebsiteSaving,
  didSaveError,
  undoDisabled,
  redoDisabled,
  element,
  onFontChange,
  onFontWeightChange,
  onFontStyleChange,
  onLinkChange,
}: ToolbarProps) => {
  const [websiteSizePick, setWebsiteSizePick] = useState<
    'mobile' | 'tablet' | 'laptop' | 'desktop'
  >('desktop')
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [linkHref, setLinkHref] = useState<string | null>(null)
  const [isSaveButtonGray, setIsSaveButtonGray] = useState(true)
  const [primaryColor, setPrimaryColor] = useState(
    colorPalette && colorPalette['primary-color']
  )

  useEffect(() => {
    if (colorPalette) {
      setPrimaryColor(colorPalette['primary-color'])
    }
  }, [colorPalette])

  useEffect(() => {
    if (element) {
      // Set font styles based on Tailwind classes
      setIsBold(element.classList.contains('font-bold'))
      setIsItalic(element.classList.contains('italic'))
      setIsUnderline(element.classList.contains('underline'))

      // Set link href
      const linkElement = element.closest('a')
      setLinkHref(linkElement ? linkElement.getAttribute('href') : null)
    }
  }, [element])

  useEffect(() => {
    if (isWebsiteSaving) {
      setIsSaveButtonGray(false)
      setTimeout(() => {
        setIsSaveButtonGray(true)
      }, 2000)
    }
  }, [isWebsiteSaving])

  const changeViewport = (size: 'mobile' | 'tablet' | 'laptop' | 'desktop') => {
    setWebsiteSizePick(size)
    onViewportWidthChange && onViewportWidthChange(size)
  }

  const cleanFontFamily = (fontFamily: string) => {
    // Remove quotes, fallback fonts, and trim whitespace
    return fontFamily.split(',')[0].replace(/["']/g, '').trim()
  }

  const handleFontChange = (font: Font) => {
    if (element) {
      onFontChange(font, element)
    }
  }

  const fontSizeClasses = [
    'text-xs',
    'text-sm',
    'text-base',
    'text-lg',
    'text-xl',
    'text-2xl',
    'text-3xl',
    'text-4xl',
    'text-5xl',
    'text-6xl',
    'text-7xl',
    'text-8xl',
    'text-9xl',
  ]

  const getCurrentBreakpoint = () => {
    switch (websiteSizePick) {
      case 'desktop':
        return '2xl'
      case 'laptop':
        return 'xl'
      case 'tablet':
        return 'md'
      case 'mobile':
        return ''
      default:
        return ''
    }
  }

  const handleFontSizeChange = (size: string) => {
    if (element) {
      // Determine current breakpoint
      const breakpoint = getCurrentBreakpoint()

      // Remove existing font-size classes for current breakpoint
      const classesToRemove = fontSizeClasses.map((cls) =>
        breakpoint ? `${breakpoint}:${cls}` : cls
      )
      element.classList.remove(
        ...classesToRemove.filter((cls) => element.classList.contains(cls))
      )

      // Add new font-size class with breakpoint prefix
      const newClass = breakpoint ? `${breakpoint}:${size}` : size
      element.classList.add(newClass)

      // Trigger save in WebsiteChatPage
      onFontWeightChange(size, element)
    }
  }

  const handleFontStyleChange = (style: 'bold' | 'italic' | 'underline') => {
    if (element) {
      switch (style) {
        case 'bold':
          setIsBold(!isBold)
          break
        case 'italic':
          setIsItalic(!isItalic)
          break
        case 'underline':
          setIsUnderline(!isUnderline)
          break
      }
      onFontStyleChange(style, element)
    }
  }

  const handleLinkChange = (href: string | null) => {
    if (element) {
      setLinkHref(href)
      onLinkChange(href, element)
    }
  }

  const isElementSelected = !!element

  return (
    <div className="mb-2 flex h-12 flex-row items-center justify-between rounded bg-white p-1 shadow-md">
      <div className="flex h-full items-center space-x-1">
        {/* Undo/Redo */}
        <div className="flex h-full items-center space-x-1">
          <ToolbarButton
            icon="fa-undo"
            tooltip="Undo"
            onClick={onUndoClick}
            disabled={undoDisabled}
          />
          <ToolbarButton
            icon="fa-redo"
            tooltip="Redo"
            onClick={onRedoClick}
            disabled={redoDisabled}
          />
          {isWebsiteSaving && !didSaveError && (
            <div className="hidden sm:block">
              <ToolbarButton
                icon="fa-spinner-third fa-spin text-gray-500"
                tooltip="Saving..."
                onClick={onSaveClick}
                disabled={false}
              />
            </div>
          )}
          {!isWebsiteSaving && !didSaveError && (
            <div className="hidden sm:block">
              <ToolbarButton
                icon={`fa-check-circle transition-colors duration-1000 ${
                  isSaveButtonGray ? 'text-gray-500' : 'text-green-500'
                } `}
                tooltip="Saved"
                onClick={onSaveClick}
                disabled={false}
              />
            </div>
          )}
          {didSaveError && (
            <div className="hidden sm:block">
              <ToolbarButton
                icon="fa-exclamation-circle text-red-500"
                tooltip="Error Saving"
                onClick={onSaveClick}
                disabled={false}
              />
            </div>
          )}
          <div className="group relative flex items-center">
            <button
              onClick={onColorChangeClick}
              className="mx-2 h-5 w-5 cursor-pointer rounded border border-gray-300"
              style={{ backgroundColor: primaryColor }}
            />
            <Tooltip text="Colors" />
          </div>
        </div>

        {isElementSelected && (
          <div className="hidden h-full lg:flex">
            <div className="mx-1 h-full w-px bg-gray-300"></div>

            {/* Font and Size */}
            <div className="flex h-full items-center space-x-1">
              <div className="w-32">
                <FontPicker
                  apiKey={process.env.GOOGLE_FONTS_API_KEY}
                  activeFontFamily={
                    element
                      ? cleanFontFamily(
                          window.getComputedStyle(element).fontFamily
                        )
                      : ''
                  }
                  bodyFont={
                    element &&
                    element.ownerDocument &&
                    element.ownerDocument.body
                      ? cleanFontFamily(
                          window.getComputedStyle(element.ownerDocument.body)
                            .fontFamily
                        )
                      : ''
                  }
                  onChange={handleFontChange}
                  disabled={!isElementSelected}
                />
              </div>
              <FontSizeListBox
                onChange={handleFontSizeChange}
                disabled={!isElementSelected}
                element={element}
                websiteSizePick={websiteSizePick}
              />
            </div>

            <div className="mx-1 h-full w-px bg-gray-300"></div>

            {/* Text Formatting */}
            <div className="flex h-full items-center space-x-1">
              <ToolbarButton
                icon="fa-bold"
                tooltip="Bold"
                active={isBold}
                onClick={() => handleFontStyleChange('bold')}
                disabled={!isElementSelected}
              />
              <ToolbarButton
                icon="fa-italic"
                tooltip="Italic"
                active={isItalic}
                onClick={() => handleFontStyleChange('italic')}
                disabled={!isElementSelected}
              />
              <ToolbarButton
                icon="fa-underline"
                tooltip="Underline"
                active={isUnderline}
                onClick={() => handleFontStyleChange('underline')}
                disabled={!isElementSelected}
              />
            </div>

            <div className="mx-1 h-full w-px bg-gray-300"></div>

            <LinkButton
              href={linkHref}
              onChange={handleLinkChange}
              disabled={!isElementSelected}
            />
          </div>
        )}
      </div>

      <div className="flex h-full items-center">
        <div className="flex items-center">
          <a
            className={`mr-2 rounded border border-gray-300 bg-white px-2 py-1 text-xs hover:bg-gray-100`}
            href={`/website-preview?id=${website.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="mr-1">Preview</span>
            <span className="hidden sm:inline">Website</span>
          </a>
        </div>
        {/* Viewport Options */}
        <div className="mr-1 flex h-full items-center space-x-1">
          <ToolbarButton
            icon="fa-mobile-notch"
            tooltip="Mobile"
            active={websiteSizePick === 'mobile'}
            onClick={() => changeViewport('mobile')}
          />
          <ToolbarButton
            icon="fa-tablet"
            tooltip="Tablet"
            active={websiteSizePick === 'tablet'}
            onClick={() => changeViewport('tablet')}
          />
          <ToolbarButton
            icon="fa-laptop"
            tooltip="Laptop"
            active={websiteSizePick === 'laptop'}
            onClick={() => changeViewport('laptop')}
          />
          <ToolbarButton
            icon="fa-desktop"
            tooltip="Desktop"
            active={websiteSizePick === 'desktop'}
            onClick={() => changeViewport('desktop')}
          />
        </div>

        <div className="mx-1 h-full w-px bg-gray-300"></div>

        {/* Page Picker */}
        <div className="flex h-full items-center space-x-1">
          <PageListBox
            activePage={activePage}
            pages={website.Pages}
            onNewPageClicked={onNewPageClicked}
            changeActivePage={changeActivePage}
            onDeletePageClicked={onDeletePageClicked}
          />
        </div>
      </div>
    </div>
  )
}

const FontSizeListBox = ({
  onChange,
  disabled,
  element,
  websiteSizePick,
}: {
  onChange: (value: string) => void
  disabled: boolean
  element: HTMLElement
  websiteSizePick: 'mobile' | 'tablet' | 'laptop' | 'desktop'
}) => {
  const getCurrentBreakpoint = () => {
    switch (websiteSizePick) {
      case 'desktop':
        return '2xl'
      case 'laptop':
        return 'xl'
      case 'tablet':
        return 'md'
      case 'mobile':
        return ''
      default:
        return ''
    }
  }

  const options = useMemo(
    () => [
      { value: 'text-xs', name: 'Extra Small' },
      { value: 'text-sm', name: 'Small' },
      { value: 'text-base', name: 'Regular' },
      { value: 'text-lg', name: 'Large' },
      { value: 'text-xl', name: 'Extra Large' },
      { value: 'text-2xl', name: '2x Large' },
      { value: 'text-3xl', name: '3x Large' },
      { value: 'text-4xl', name: '4x Large' },
      { value: 'text-5xl', name: '5x Large' },
      { value: 'text-6xl', name: '6x Large' },
      { value: 'text-7xl', name: '7x Large' },
      { value: 'text-8xl', name: '8x Large' },
      { value: 'text-9xl', name: '9x Large' },
    ],
    []
  )

  const findFontSizeClassForBreakpoint = (
    element: HTMLElement,
    currentBreakpoint: string
  ) => {
    // List of Tailwind breakpoints in order of specificity
    const breakpoints = ['2xl', 'xl', 'lg', 'md', 'sm', '']
    const fontSizeClasses = [
      'text-xs',
      'text-sm',
      'text-base',
      'text-lg',
      'text-xl',
      'text-2xl',
      'text-3xl',
      'text-4xl',
      'text-5xl',
      'text-6xl',
      'text-7xl',
      'text-8xl',
      'text-9xl',
    ]

    // Find the most specific class starting from the current breakpoint
    const currentBreakpointIndex = breakpoints.indexOf(currentBreakpoint)
    let currentElement: HTMLElement | null = element

    while (currentElement) {
      // For each element, check for font size classes starting from the current breakpoint
      for (
        let bpIndex = currentBreakpointIndex;
        bpIndex < breakpoints.length;
        bpIndex++
      ) {
        const bp = breakpoints[bpIndex]
        const prefix = bp ? `${bp}:` : ''
        // Check if the element has a font size class for this breakpoint
        for (const cls of fontSizeClasses) {
          const fullClass = `${prefix}${cls}`
          if (currentElement.classList.contains(fullClass)) {
            return fullClass // Found the font size class
          }
        }
      }
      // Move up to the parent element
      currentElement = currentElement.parentElement
    }

    // If no font size class is found, return an empty string or a default value
    return ''
  }

  const currentBreakpoint = getCurrentBreakpoint()

  // Use `findFontSizeClassForBreakpoint` to determine the applied class once
  const appliedFontSizeClass = useMemo(() => {
    return findFontSizeClassForBreakpoint(element, currentBreakpoint)
  }, [element, currentBreakpoint])

  // Extract the actual font size (e.g., text-3xl) by removing the breakpoint prefix (e.g., lg:)
  const extractedFontSize = appliedFontSizeClass.split(':').pop() || ''

  // Now find the matching option for the extracted font size
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === extractedFontSize) || options[2]
  )

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSelectedOption(
      options.find((option) => option.value === extractedFontSize) || options[2]
    )
  }, [extractedFontSize, options])

  return (
    <Listbox
      value={selectedOption}
      onChange={(option) => {
        setSelectedOption(option)
        setIsOpen(false)
        onChange(option.value)
      }}
      disabled={disabled}
      by="value"
    >
      <div>
        <Listbox.Button
          className={`relative h-full w-full rounded bg-white py-1 pl-3 pr-6 text-left text-sm font-normal ${
            disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100'
          } focus:outline-none focus-visible:border-blue-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600`}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <span className="block truncate">{selectedOption.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <i className="fa-regular fa-angle-down" aria-hidden="true"></i>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          show={isOpen}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="w-34 absolute z-10 mt-3 max-h-60 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-gray-200 text-[#333]' : 'text-gray-900'
                  }`
                }
              >
                {() => {
                  const isSelected = option.value === selectedOption.value
                  return (
                    <>
                      <span
                        className={`block truncate ${
                          isSelected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.name}
                      </span>
                      {isSelected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#333]">
                          <i
                            className="fa-solid fa-check"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </>
                  )
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

const PageListBox = ({
  activePage,
  pages,
  onNewPageClicked,
  changeActivePage,
  onDeletePageClicked,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Listbox
      value={activePage}
      onChange={(page) => {
        setIsOpen(false)
        changeActivePage(page)
      }}
    >
      <div
        id="page-dropdown"
        className="relative max-w-[200px] flex-grow text-sm"
      >
        <Listbox.Button
          className="relative h-full w-full rounded bg-white py-2 pl-3 pr-4 text-left font-normal hover:bg-gray-100 focus:outline-none focus-visible:border-blue-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="block truncate pr-4">{activePage.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <i className="fa-regular fa-angle-down" aria-hidden="true"></i>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          show={isOpen}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute right-0 z-10 mt-2 max-h-60 w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {pages.map((page) => {
              return (
                <Listbox.Option
                  key={page.id}
                  value={page}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-10 ${
                      active ? 'bg-gray-200 text-[#333]' : 'text-gray-900'
                    }`
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {page.name}
                      </span>
                      {page.isHomepage === false && (
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            if (
                              confirm(
                                `Are you sure you want to delete the ${page.name} page?`
                              )
                            ) {
                              onDeletePageClicked(page)
                            }
                          }}
                          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#333]">
                          <i
                            className="fa-solid fa-check"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            })}
            <button
              onClick={() => {
                onNewPageClicked && onNewPageClicked()
                setIsOpen(false)
              }}
              className="w-full border-t border-gray-200 py-2 pl-3 text-left text-black"
            >
              <i className="fa-regular fa-plus mr-2 pr-2"></i>
              <span>New Page</span>
            </button>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

const ToolbarButton = ({
  icon,
  tooltip,
  onClick,
  disabled,
  active,
}: {
  icon: string
  tooltip: string
  onClick: () => void
  disabled?: boolean
  active?: boolean
}) => (
  <div className="group relative h-[30px] w-[25px] md:w-[30px]">
    <button
      className={`h-full w-full rounded px-1 py-1 text-sm ${
        active ? 'bg-gray-100' : 'bg-white hover:bg-gray-100'
      } ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <i className={`fa-regular ${icon}`}></i>
    </button>
    <Tooltip text={tooltip} />
  </div>
)

const LinkButton = ({
  href,
  onChange,
  disabled,
}: {
  href: string | null
  onChange: (href: string | null) => void
  disabled: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState(href || '')

  useEffect(() => {
    setInputValue(href || '')
  }, [href])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = () => {
    if (inputValue.trim() === '') {
      onChange(null)
    } else {
      onChange(inputValue)
    }
    setIsOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="relative flex h-full items-center">
      <ToolbarButton
        icon="fa-link"
        tooltip="Link"
        active={!!href}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      />
      {isOpen && (
        <div className="absolute left-0 top-full z-10 mt-2 w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-2">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleSubmit}
              onKeyDown={handleKeyDown}
              placeholder="Enter URL or leave empty to remove"
              className="w-full rounded border p-1 text-sm"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PageToolbar
