import { Website, Page } from 'types/graphql'

type ToolbarProps = {
  website: Website
  activePage: Page
  onShowWebsiteClicked: () => void
}
const WebsiteLoadingProgressBar = ({
  website,
  activePage,
  onShowWebsiteClicked,
}: ToolbarProps) => {
  if (!activePage) {
    return <div>Loading...</div>
  }

  let progress = 0

  if (
    website.headerCodeSection &&
    website.headerCodeSection.html !== '<div>Loading...</div>'
  ) {
    progress++
  }

  if (
    website.footerCodeSection &&
    website.footerCodeSection.html !== '<div>Loading...</div>'
  ) {
    progress++
  }

  activePage.codeSections &&
    activePage.codeSections.forEach((codeSection) => {
      if (codeSection.html !== '<div>Loading...</div>') {
        progress++
      }
    })

  if (activePage && activePage.imagesKeyword) {
    progress++
  }

  if (website.Pages && website.Pages.length > 1) {
    progress++
  }

  if (website.colorPalette) {
    progress++
  }

  //1 for header
  //1 for footer
  //1 for imagesKeyword (images have been selected)
  //1 for more than the homepage created
  //1 for color palette
  const total = 5 + activePage.codeSections.length

  console.log('progress:', progress, '/', total)

  const doneBuilding = website.finishedGeneratingAt !== null

  return (
    <div className="mb-4 flex flex-col items-center justify-between rounded bg-white p-2 shadow-md">
      {!doneBuilding && (
        <>
          <div className="mb-4 text-center text-lg font-semibold text-gray-700">
            Building your website...
          </div>
          <div className="relative h-6 w-full overflow-hidden rounded-full bg-gray-200">
            <div
              className="absolute left-0 top-0 h-full rounded-full bg-blue-500 transition-all duration-1000 ease-in-out"
              style={{
                width: `${(progress / total) * 100}%`,
              }}
            ></div>
          </div>
        </>
      )}
      {doneBuilding && (
        <>
          <div className="text-center text-lg font-semibold text-gray-700">
            Your website is ready!
          </div>
          <div className="flex items-center justify-center">
            <button
              className="mt-2 rounded bg-green-500 px-4 py-2 text-white"
              onClick={onShowWebsiteClicked}
            >
              Go To Website
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default WebsiteLoadingProgressBar
