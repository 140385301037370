import { useEffect } from 'react'
import { useState } from 'react'

import { Loader2, Check, Copy, ExternalLink } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { parse } from 'tldts'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useWebsiteDomainPolling } from 'src/hooks/useWebsiteDomainPolling'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/Accordion'
import { Alert, AlertDescription } from '@/components/ui/Alert'
import { Badge } from '@/components/ui/Badge'
import { Button } from '@/components/ui/Button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardFooter,
  CardTitle,
} from '@/components/ui/Card'
import { Input } from '@/components/ui/Input'
import { Toast } from '@/components/ui/Toast'

const SAVE_WEBSITE_CUSTOM_DOMAIN = gql`
  mutation SaveWebsiteCustomDomainSettingsPanelMutation(
    $id: String!
    $customDomain: String
  ) {
    updateWebsiteCustomDomain(id: $id, customDomain: $customDomain) {
      __typename
      id
      customDomain
      verifiedDomain
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
      Domain {
        id
        nameserverStatus
        verificationStatus
      }
    }
  }
`

const nameservers = [
  'ns1.landingsite.ai',
  'ns2.landingsite.ai',
  'ns3.landingsite.ai',
  'ns4.landingsite.ai',
]

const registrarGuides = [
  {
    name: '1and1',
    url: 'https://help.1and1.com/domains-c36931/manage-domains-c79822/dns-c37586/use-your-own-name-server-for-a-1and1-domain-a594904.html',
  },
  {
    name: '101Domain',
    url: 'https://help.101domain.com/domain-management/nameservers-dns',
  },
  {
    name: 'Amazon Route 53',
    url: 'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-name-servers-glue-records.html#domain-name-servers-glue-records-adding-changing',
  },
  {
    name: 'Blacknight',
    url: 'https://help.blacknight.com/hc/en-us/articles/212512229-Changing-nameservers-in-cp-blacknight-com',
  },
  {
    name: 'BlueHost',
    url: 'https://www.bluehost.com/help/article/custom-nameservers#name-server',
  },
  {
    name: 'DirectNIC',
    url: 'https://directnic.com/knowledge/article/33:how%2Bdo%2Bi%2Bmodify%2Bname%2Bservers%2Bfor%2Bmy%2Bdomain%2Bname%253F',
  },
  {
    name: 'DNSMadeEasy',
    url: 'http://www.dnsmadeeasy.com/support/faq/',
  },
  {
    name: 'Domain.com',
    url: 'https://www.domain.com/help/article/domain-management-how-to-update-nameservers',
  },
  {
    name: 'Dotster',
    url: 'https://www.dotster.com/help/article/domain-management-how-to-update-nameservers',
  },
  {
    name: 'DreamHost',
    url: 'https://help.dreamhost.com/hc/en-us/articles/360038897151',
  },
  {
    name: 'EasyDNS',
    url: 'https://www.easydns.com/nameservers/',
  },
  {
    name: 'Enom',
    url: 'http://www.enom.com/kb/kb/kb_0086_how-to-change-dns.htm',
  },
  {
    name: 'FastDomain',
    url: 'http://www1.domain.com/help/article/domain-management-how-to-update-nameservers',
  },
  {
    name: 'FlokiNET',
    url: 'https://billing.flokinet.is/index.php?rp%3D/knowledgebase/57/Nameserver-changes.html',
  },
  {
    name: 'Gandi',
    url: 'https://wiki.gandi.net/en/dns/change',
  },
  {
    name: 'GoDaddy',
    url: 'https://www.godaddy.com/help/change-nameservers-for-your-domain-names-664',
  },
  {
    name: 'Google Domains',
    url: 'https://support.google.com/domains/answer/3290309?hl%3Den',
  },
  {
    name: 'HostGator',
    url: 'http://support.hostgator.com/articles/hosting-guide/lets-get-started/dns-name-servers/how-do-i-change-my-dns-or-name-servers',
  },
  {
    name: 'Hostico',
    url: 'https://hostico.ro/docs/setarea-nameserverelor-din-contul-de-client-hostico/',
  },
  {
    name: 'HostMonster',
    url: 'https://my.hostmonster.com/cgi/help/222',
  },
  {
    name: 'Internetbs',
    url: 'https://internetbs.net/faq/content/1/13/en/how-to-update-the-list-of-dns-nameservers-linked-to-a-domain.html?highlight%3Dnameservers',
  },
  {
    name: 'iPage',
    url: 'https://www.ipage.com/help/article/domain-management-how-to-update-nameservers',
  },
  {
    name: 'MediaTemple',
    url: 'https://mediatemple.net/community/products/dv/204643220/how-do-i-edit-my-domain%27s-nameservers',
  },
  {
    name: 'MelbourneIT',
    url: 'https://support.melbourneit.com.au/articles/help/Domain-Name-Administration-FAQ/?q%3Dedit%2Bnameservers%26fs%3DSearch%26pn%3D1',
  },
  {
    name: 'Moniker',
    url: 'https://faq.moniker.com/register-own-nameservers',
  },
  {
    name: 'Name.com',
    url: 'https://www.name.com/support/articles/205934547-Changing-Your-Name-Servers',
  },
  {
    name: 'Namecheap',
    url: 'https://www.namecheap.com/support/knowledgebase/article.aspx/767/10/how-can-i-change-the-nameservers-for-my-domain',
  },
  {
    name: 'Network Solutions',
    url: 'https://www.networksolutions.com/manage-it/edit-nameservers.jsp',
  },
  {
    name: 'OVH',
    url: 'https://docs.ovh.com/gb/en/domains/web_hosting_general_information_about_dns_servers/#step-2-edit-your-domains-dns-servers',
  },
  {
    name: 'Porkbun',
    url: 'https://kb.porkbun.com/article/22-how-to-change-your-nameservers',
  },
  {
    name: 'Rackspace',
    url: 'https://support.rackspace.com/how-to/rackspace-name-servers/',
  },
  {
    name: 'Register.com',
    url: 'https://knowledge.web.com/subjects/article/KA-01114/en-us',
  },
  {
    name: 'Site5',
    url: 'https://knowledge.web.com/subjects/article/KA-01114/en-us',
  },
  {
    name: 'Softlayer',
    url: 'https://console.bluemix.net/docs/infrastructure/dns/add-edit-custom-name-servers.html#add-edit-or-delete-custom-name-servers-for-a-domain',
  },
  {
    name: 'Tucows',
    url: 'http://www.tucowsdomains.com/name-server-dns-changes/how-do-i-change-my-name-servers-dns/',
  },
  {
    name: 'Yahoo',
    url: 'http://support.hostgator.com/articles/how-to-change-name-servers-with-yahoo-com',
  },
  {
    name: 'Yola',
    url: 'https://www.yola.com/tutorials/article/Changing-the-name-servers-1285944436498/Publishing_domains_and_email',
  },
]

const CustomDomainSettings = ({
  websiteId,
  stripePlan,
  onUpgradeClicked,
}: {
  websiteId: string
  stripePlan: string
  onUpgradeClicked: () => void
}) => {
  const [customDomain, setCustomDomain] = useState('')
  const [addingCustomDomain, setAddingCustomDomain] = useState(false)
  const [domainErrorString, setDomainErrorString] = useState<string | null>(
    null
  )
  const [domainStatusString, setDomainStatusString] = useState<string | null>(
    null
  )

  const [saveWebsiteCustomDomain] = useMutation(SAVE_WEBSITE_CUSTOM_DOMAIN)
  const [customDomainAnswer, setCustomDomainAnswer] = useState(null)
  const [selectedDomainAnswer, setSelectedDomainAnswer] = useState(null)

  const { websiteDomainInfo, useQueryRef } = useWebsiteDomainPolling({
    websiteId,
  })

  const posthog = usePostHog()

  useEffect(() => {
    posthog?.capture('Custom Domain Tab Visited')
  }, [])

  const extractDomain = (url: string) => {
    const parsed = parse(url)
    if (parsed.subdomain && parsed.subdomain !== 'www') {
      return `${parsed.subdomain}.${parsed.domain}`
    }
    return parsed.domain
  }

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    Toast({
      title: 'Nameserver copied to clipboard',
    })
  }

  const onClickCustomDomainAdd = async (event) => {
    event.preventDefault()

    if (addingCustomDomain) {
      return
    }
    setAddingCustomDomain(true)
    setDomainStatusString(null)
    setDomainErrorString(null)

    console.log('new domain:', customDomain)

    posthog?.capture('Custom Domain Added')

    const domainFromString = extractDomain(customDomain)
    console.log('domainFromString:', domainFromString)

    setCustomDomain(domainFromString)

    const statusTexts = [
      'Creating your domain...',
      'Importing DNS records from your old domain...',
      'Setting up SSL certificates...',
      'Double checking everything...',
      'Almost done...',
    ]

    // Show first message immediately
    setDomainStatusString(statusTexts[0])
    let statusTextIndex = 1

    const intervalId = setInterval(async () => {
      if (statusTextIndex < statusTexts.length) {
        setDomainStatusString(statusTexts[statusTextIndex])
      }
      statusTextIndex = statusTextIndex + 1

      if (statusTextIndex > statusTexts.length + 3) {
        setDomainStatusString(
          'This is taking longer than expected... try refreshing the page'
        )
        clearInterval(intervalId)
      }
    }, 3000)

    try {
      await saveWebsiteCustomDomain({
        variables: {
          id: websiteId,
          customDomain: domainFromString,
        },
      })

      await useQueryRef.refetch()
      clearInterval(intervalId)
      setDomainStatusString(null)
    } catch (e) {
      clearInterval(intervalId)
      setDomainStatusString(null)
      setDomainErrorString(e.message)
    }

    setAddingCustomDomain(false)
  }

  const [isRemoving, setIsRemoving] = useState(false)

  const deleteCustomDomain = async (event) => {
    event.preventDefault()
    setIsRemoving(true)

    try {
      setCustomDomain(null)
      setDomainStatusString(null)
      setDomainErrorString(null)

      await saveWebsiteCustomDomain({
        variables: {
          id: websiteId,
          customDomain: null,
        },
      })
      await useQueryRef.refetch()
    } catch (error) {
      // Optionally handle error
      console.error('Error removing domain:', error)
    } finally {
      setIsRemoving(false)
    }
  }

  useEffect(() => {
    if (selectedDomainAnswer === 'I have purchased a domain already') {
      posthog?.capture('Selected - Already Purchased Domain')
    }

    if (selectedDomainAnswer === 'I need a domain') {
      posthog?.capture('Selected - Need a Domain')
    }
  }, [selectedDomainAnswer])

  const [isChecking, setIsChecking] = useState(false)

  const handleCheckNow = async () => {
    setIsChecking(true)
    await useQueryRef.refetch()
    setIsChecking(false)
  }

  return (
    <div className="container max-w-3xl py-8">
      {stripePlan === 'Free' && (
        <Card className="">
          <CardHeader className="space-y-1">
            <div className="flex items-center gap-2">
              <i className="fa-regular fa-lock h-4 w-4 text-muted-foreground" />
              <CardTitle>Custom Domain</CardTitle>
            </div>
            <CardDescription>
              Take your website to the next level with a professional domain
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="rounded-lg bg-muted p-4">
              <p className="text-sm text-muted-foreground">
                Custom domains are available on paid plans. Upgrade to:
              </p>
              <ul className="mt-2 space-y-2 text-sm">
                <li className="flex items-center">• Connect your own domain</li>
                <li className="flex items-center">
                  • Boost your brand credibility
                </li>
                <li className="flex items-center">
                  • Set up email addresses with your domain
                </li>
              </ul>
            </div>
          </CardContent>
          <CardFooter>
            <Button className="w-full" onClick={onUpgradeClicked}>
              Upgrade to Enable Custom Domains
            </Button>
          </CardFooter>
        </Card>
      )}
      {stripePlan !== 'Free' &&
        !websiteDomainInfo.customDomain &&
        !customDomainAnswer && (
          <Card>
            <CardHeader>
              <CardTitle>Do you have a domain?</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex flex-col space-y-4">
                <Button
                  variant={
                    selectedDomainAnswer === 'I have purchased a domain already'
                      ? 'secondary'
                      : 'outline'
                  }
                  className="justify-between"
                  onClick={() =>
                    setSelectedDomainAnswer('I have purchased a domain already')
                  }
                >
                  <span>I have a domain already</span>
                  {selectedDomainAnswer ===
                  'I have purchased a domain already' ? (
                    <Check className="h-4 w-4" />
                  ) : (
                    <div className="h-4 w-4 rounded-full border" />
                  )}
                </Button>

                <Button
                  variant={
                    selectedDomainAnswer === 'I need a domain'
                      ? 'secondary'
                      : 'outline'
                  }
                  className="justify-between"
                  onClick={() => setSelectedDomainAnswer('I need a domain')}
                >
                  <span>I need a domain</span>
                  {selectedDomainAnswer === 'I need a domain' ? (
                    <Check className="h-4 w-4" />
                  ) : (
                    <div className="h-4 w-4 rounded-full border" />
                  )}
                </Button>

                <Button
                  className="mt-4"
                  disabled={!selectedDomainAnswer}
                  onClick={() => {
                    if (
                      selectedDomainAnswer ===
                      'I have purchased a domain already'
                    ) {
                      setCustomDomainAnswer('already_purchased')
                    } else {
                      navigate('/domain-search?websiteId=' + websiteId)
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </CardContent>
          </Card>
        )}

      {!websiteDomainInfo.customDomain &&
        customDomainAnswer === 'already_purchased' && (
          <Card>
            <CardHeader>
              <CardTitle>Set Up Your Domain</CardTitle>
              <CardDescription>
                Connect your domain to start using it with your website
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <Input
                    placeholder="yourdomain.com"
                    value={customDomain}
                    onChange={(e) => setCustomDomain(e.target.value)}
                  />
                  <Button
                    onClick={onClickCustomDomainAdd}
                    disabled={addingCustomDomain}
                  >
                    {addingCustomDomain && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    {addingCustomDomain ? 'Adding...' : 'Add Domain'}
                  </Button>
                </div>

                {domainStatusString && (
                  <Alert>
                    <AlertDescription className="flex items-center gap-2">
                      <Loader2 className="h-4 w-4 animate-spin" />
                      {domainStatusString}
                    </AlertDescription>
                  </Alert>
                )}

                {domainErrorString && (
                  <Alert variant="destructive">
                    <AlertDescription>{domainErrorString}</AlertDescription>
                  </Alert>
                )}
              </div>
            </CardContent>
          </Card>
        )}

      {stripePlan !== 'Free' && websiteDomainInfo.customDomain && (
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <CardTitle>{websiteDomainInfo.customDomain}</CardTitle>
                {websiteDomainInfo.customDomain ===
                websiteDomainInfo.verifiedDomain ? (
                  <Badge variant="secondary">
                    <Check className="mr-1 h-3 w-3" /> Verified
                  </Badge>
                ) : (
                  <Badge variant="outline">Not Verified</Badge>
                )}
              </div>
              <Button
                variant="outline"
                onClick={deleteCustomDomain}
                disabled={isRemoving}
                className="text-gray-500 hover:text-gray-700"
              >
                {isRemoving ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {websiteDomainInfo.customDomain ===
                    websiteDomainInfo.verifiedDomain
                      ? 'Disconnecting...'
                      : 'Canceling...'}
                  </>
                ) : websiteDomainInfo.customDomain ===
                  websiteDomainInfo.verifiedDomain ? (
                  'Disconnect Domain'
                ) : (
                  'Cancel Setup'
                )}
              </Button>
            </div>
          </CardHeader>

          {websiteDomainInfo.customDomain !==
            websiteDomainInfo.verifiedDomain && (
            <CardContent className="space-y-6">
              <div className="space-y-4">
                <h3 className="font-semibold">
                  Complete your nameserver setup:
                </h3>

                <ol className="list-inside list-decimal space-y-4">
                  <li>Log in to your domain registrar</li>
                  <li>
                    Go to the settings for {websiteDomainInfo.customDomain}
                  </li>
                  <li>Change your nameservers to:</li>
                </ol>

                <div className="grid gap-2">
                  {nameservers.map((ns) => (
                    <div
                      key={ns}
                      className="flex items-center justify-between rounded-md border p-2"
                    >
                      <code className="font-mono">{ns}</code>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => copyToClipboard(ns)}
                      >
                        <Copy className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>

                <Accordion type="single" collapsible>
                  <AccordionItem value="registrar-guides">
                    <AccordionTrigger>
                      Need help? View registrar-specific guides
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="grid grid-cols-2 gap-2">
                        {registrarGuides.map((guide) => (
                          <a
                            key={guide.name}
                            href={guide.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-2 rounded-md p-2 hover:bg-accent"
                          >
                            {guide.name}
                            <ExternalLink className="h-4 w-4" />
                          </a>
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>

                <Alert>
                  <AlertDescription className="flex items-center justify-between">
                    <span>
                      After changing the nameservers, it can take 5 minutes to
                      48 hours for changes to take effect. We'll automatically
                      verify your domain once the changes are detected.
                    </span>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleCheckNow}
                      disabled={isChecking}
                      className="ml-4 whitespace-nowrap"
                    >
                      {isChecking ? (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Checking...
                        </>
                      ) : (
                        'Check Now'
                      )}
                    </Button>
                  </AlertDescription>
                </Alert>
              </div>
            </CardContent>
          )}
        </Card>
      )}
    </div>
  )
}

export default CustomDomainSettings
