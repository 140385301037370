import { useCallback, useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'

import { useAuth } from 'src/auth'
import {
  GalleryType,
  Media,
  SearchImagesResponseType,
  searchImages,
} from 'src/lib/media'

export type GalleryProps = {
  websiteId: string
  initialGalleryType: GalleryType
  initialKeyword: string
  existingMedia: { id: string; src: string; type: string }[]
  onClose: () => void
  onMediaSelected: (media: Media) => void
}

const allowedGalleryTypes = ['images', 'videos', 'illustrations', 'animations']

const singularToPluralMap: Record<string, GalleryType> = {
  image: 'images',
  video: 'videos',
  illustration: 'illustrations',
  animation: 'animations',
}

function normalizeGalleryType(type: string): GalleryType {
  // Convert singular form to plural form if present in the map
  const pluralType = singularToPluralMap[type] || type

  // Check if the plural type is allowed, otherwise default to 'images'
  return allowedGalleryTypes.includes(pluralType as GalleryType)
    ? (pluralType as GalleryType)
    : 'images'
}

const Gallery = ({
  websiteId,
  initialGalleryType,
  initialKeyword,
  existingMedia,
  onClose,
  onMediaSelected,
}: GalleryProps) => {
  const { getToken } = useAuth()

  const [galleryShouldShowFreeImages, setGalleryShouldShowFreeImages] =
    useState(true)
  const [galleryShouldShowProImages, setGalleryShouldShowProImages] =
    useState(true)
  const [imagesGalleryKeyword, setImagesGalleryKeyword] =
    useState(initialKeyword)
  const [debouncedImagesGalleryKeyword, setDebouncedImagesGalleryKeyword] =
    useState(initialKeyword)
  const [isLoading, setIsLoading] = useState(false)
  const [galleryPageIndex, setGalleryPageIndex] = useState(1)
  const [galleryResponse, setGalleryResponse] =
    useState<SearchImagesResponseType>({
      total: 0,
      results: [],
    })
  const [galleryType, setGalleryType] = useState<GalleryType>(
    normalizeGalleryType(initialGalleryType)
  )

  // Update debounced value after delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedImagesGalleryKeyword(imagesGalleryKeyword)
    }, 300)

    // Cleanup the timeout if inputValue changes before the timeout completes
    return () => {
      clearTimeout(handler)
    }
  }, [imagesGalleryKeyword])

  const search = useCallback(async () => {
    setIsLoading(true)

    const token = await getToken()

    const imageSearchResponse = await searchImages({
      token,
      websiteId,
      keyword: debouncedImagesGalleryKeyword,
      page: galleryPageIndex,
      perPage: 8,
      galleryType: galleryType as GalleryType,
      galleryShouldShowFreeImages,
      galleryShouldShowProImages,
    })
    setIsLoading(false)

    if (!imageSearchResponse) {
      return
    }

    setGalleryResponse(imageSearchResponse)
  }, [
    galleryType,
    galleryPageIndex,
    galleryShouldShowFreeImages,
    galleryShouldShowProImages,
    debouncedImagesGalleryKeyword,
    websiteId,
  ])

  const galleryOnClickPrevious = () => {
    setGalleryPageIndex((prev) => prev - 1)
  }

  const galleryOnClickNext = () => {
    setGalleryPageIndex((prev) => prev + 1)
  }

  useEffect(() => {
    search()
  }, [search])

  return (
    <div className="relative flex flex-col items-center md:px-4">
      <div className="absolute right-0 top-0 block pr-2 pt-2">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            onClose()
          }}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <h1 className="my-4 text-center text-3xl font-medium">Gallery</h1>
      <div className="mb-6 flex w-full flex-row items-center justify-center">
        <div className="w-full max-w-[545px] md:w-1/2">
          <div className="relative inline-flex w-full items-center">
            <i className="fa-solid fa-magnifying-glass absolute left-3 text-gray-300"></i>
            <input
              type="text"
              className="w-full rounded border border-blue-400 px-3 py-2 pl-10 text-gray-800 focus:outline-none"
              value={imagesGalleryKeyword}
              onChange={(e) => {
                setImagesGalleryKeyword(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="mb-3 flex w-full flex-row items-start space-x-4 border-b border-gray-200">
        <button
          className={
            (galleryType === 'images'
              ? 'border-b border-blue-500 text-blue-500'
              : '') + ' pb-2 text-xl'
          }
          onClick={() => setGalleryType('images')}
        >
          Images
        </button>
        <button
          className={
            (galleryType === 'videos'
              ? 'border-b border-blue-500 text-blue-500'
              : '') + ' pb-2 text-xl'
          }
          onClick={() => setGalleryType('videos')}
        >
          Videos
        </button>
        <button
          className={
            (galleryType === 'illustrations'
              ? 'border-b border-blue-500 text-blue-500'
              : '') + ' pb-2 text-xl'
          }
          onClick={() => setGalleryType('illustrations')}
        >
          Illustrations
        </button>
        <button
          className={
            (galleryType === 'animations'
              ? 'border-b border-blue-500 text-blue-500'
              : '') + ' pb-2 text-xl'
          }
          onClick={() => setGalleryType('animations')}
        >
          Animations
        </button>
      </div>
      <div className="mb-3 flex w-full flex-row items-end justify-end">
        <div className="flex flex-row items-center justify-end">
          <div className="mr-3">
            {(galleryPageIndex - 1) * 8 + 1} -{' '}
            {Math.min(galleryPageIndex * 8, galleryResponse.total)} of{' '}
            {galleryResponse.total}
          </div>
          <div>
            <button
              disabled={galleryPageIndex <= 1}
              onClick={galleryOnClickPrevious}
              className="mr-3 h-[30px] w-[30px] rounded-full border border-gray-300 bg-gray-100 text-gray-600 disabled:border-gray-100 disabled:text-gray-300"
            >
              <i className="fa-regular fa-chevron-left"></i>
            </button>
            <button
              disabled={galleryPageIndex * 8 >= galleryResponse.total}
              onClick={galleryOnClickNext}
              className="h-[30px] w-[30px] rounded-full border border-gray-300 bg-gray-100 text-gray-600 disabled:border-gray-100 disabled:text-gray-300"
            >
              <i className="fa-regular fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="relative mb-12">
        {isLoading && (
          <div className="absolute z-20 mx-auto h-full w-full bg-white opacity-75 2xl:flex 2xl:items-center 2xl:justify-center">
            <div className="mx-auto mt-10 h-[35px] w-[35px] 2xl:mt-0">
              <i className="fa-regular fa-spinner-third fa-2xl animate-spin"></i>
            </div>
          </div>
        )}
        <div className="grid min-h-[656px] grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {galleryResponse.results.map((media: Media) => (
            <div
              key={media.id}
              className="max-w-96 relative h-80 cursor-pointer hover:border-2 hover:border-green-400"
              onClick={onMediaSelected.bind(this, media)}
              aria-hidden="true"
            >
              {(media.src === 'iStock' || media.src === 'iconscout') && (
                <div className="absolute right-5 top-5 bg-white">
                  <i className="fa-solid fa-pro fa-2xl"></i>
                </div>
              )}
              {media.src === 'unsplash' && (
                <div className="absolute bottom-2 right-2 text-xs text-white">
                  <span className="mr-2">Photo by</span>
                  <a
                    className="underline"
                    href={`https://unsplash.com/@${media.user.username}?utm_source=landingsite&utm_medium=referral`}
                  >
                    {media.user.name}
                  </a>
                  <span className="mx-2">on</span>
                  <a
                    className="underline"
                    href="https://unsplash.com/?utm_source=landingsite&utm_medium=referral"
                  >
                    Unsplash
                  </a>
                </div>
              )}
              {(media.type === 'image' || media.type === 'illustration') && (
                <img
                  src={media.urls.regular}
                  className="h-full w-full rounded-lg object-cover"
                  alt="available to use"
                  onContextMenu={(e) => {
                    e.preventDefault()
                  }}
                />
              )}
              {(media.type === 'video' || media.type === 'lottie') && (
                <video
                  className="h-full w-full object-contain"
                  loop
                  preload="auto"
                  autoPlay
                  muted
                  poster={media.urls.small.image}
                  src={media.urls.small.video}
                  onContextMenu={(e) => {
                    e.preventDefault()
                  }}
                ></video>
              )}

              {
                // show checkmark if media is already selected
                existingMedia.find((el) => el.id === media.id) && (
                  <div
                    id={media.id}
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
                  >
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Gallery
