import { useEffect, useRef, useState } from 'react'

import type {
  FindWebsiteDomainQuery,
  FindWebsiteDomainQueryVariables,
} from 'types/graphql'

import { TypedDocumentNode, useQuery } from '@redwoodjs/web'
import { registerFragment } from '@redwoodjs/web/apollo'

registerFragment(gql`
  fragment DomainInfo on Website {
    id
    customDomain
    verifiedDomain
    cloudflareZoneId
    cloudflareZoneActivatedOn
    cloudflareOriginalDNSHost
    cloudflareOriginalNameservers
    cloudflareOriginalRegistrar
    cloudflareNameservers
    Domain {
      id
      nameserverStatus
      verificationStatus
    }
  }
`)

const FIND_WEBSITE_DOMAIN: TypedDocumentNode<
  FindWebsiteDomainQuery,
  FindWebsiteDomainQueryVariables
> = gql`
  query FindWebsiteDomainQuery($id: String!) {
    website: website(id: $id) {
      customDomain
      verifiedDomain
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
      Domain {
        id
        nameserverStatus
        verificationStatus
      }
    }
  }
`

type UseWebsiteDomainPollingProps = {
  websiteId?: string | null
}

export const useWebsiteDomainPolling = ({
  websiteId,
}: UseWebsiteDomainPollingProps) => {
  const [secondsUntilNextCheck, setSecondsUntilNextCheck] = useState<
    number | null
  >(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const useQueryRef = useQuery<
    FindWebsiteDomainQuery,
    FindWebsiteDomainQueryVariables
  >(FIND_WEBSITE_DOMAIN, {
    variables: { id: websiteId ?? '' },
    skip: !websiteId,
  })

  const { loading, data, startPolling, stopPolling } = useQueryRef

  useEffect(() => {
    const website = data?.website
    if (website) {
      if (
        website.customDomain &&
        website.customDomain !== website.verifiedDomain
      ) {
        // Start polling every 10 seconds if domains don't match
        startPolling(10000)

        // Update seconds until next check
        intervalRef.current = setInterval(() => {
          setSecondsUntilNextCheck((prev) => {
            if (prev === null || prev <= 1) return 10
            return prev - 1
          })
        }, 1000)
      } else {
        // Stop polling if domains match or no custom domain
        stopPolling()

        // Clear the countdown interval
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
          setSecondsUntilNextCheck(null)
        }
      }
    }

    // Cleanup function
    return () => {
      stopPolling()
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [
    data?.website?.customDomain,
    data?.website?.verifiedDomain,
    startPolling,
    stopPolling,
  ])

  return {
    loading,
    websiteDomainInfo: data?.website,
    secondsUntilNextCheck,
    useQueryRef,
  }
}
