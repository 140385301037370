import { Fragment, useState, useEffect } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { usePostHog } from 'posthog-js/react'
import { Website } from 'types/graphql'

import { Link, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { useWebsiteDomainPolling } from 'src/hooks/useWebsiteDomainPolling'

const GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL = gql`
  mutation GetStripeCustomerPortalSessionUrlMutation {
    getStripeCustomerPortalSessionUrl
  }
`

type NavbarProps = {
  website?: Website
  navigateToPanel?: (panelName: string, tabName?: string) => void
  onPublishClicked?: () => Promise<void>
  onSetUpDomainClicked?: () => void
}
const Navbar = ({
  website,
  navigateToPanel,
  onPublishClicked,
  onSetUpDomainClicked,
}: NavbarProps) => {
  const { pathname } = useLocation()
  const posthog = usePostHog()
  useEffect(() => {
    posthog?.capture('$pageview')
  }, [pathname])

  const { isAuthenticated, logIn, signUp, logOut } = useAuth()

  const [loadingBillingPortal, setLoadingBillingPortal] = useState(false)
  const [hasUnpublishedChanges, setHasUnpublishedChanges] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)

  const [getStripeCustomerPortalSessionUrl] = useMutation(
    GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL
  )

  const { websiteDomainInfo } = useWebsiteDomainPolling({
    websiteId: website?.id,
  })

  useEffect(() => {
    const _hasUnpublishedChanges =
      website?.lastUpdated &&
      new Date(website.lastUpdated) > new Date(website.proPublishedAt)
    setHasUnpublishedChanges(_hasUnpublishedChanges)
  }, [website])

  useEffect(() => {
    if (isPublishing) {
      onPublishClicked().then(() => {
        setIsPublishing(false)
      })
    }
  }, [isPublishing])

  const onClickLogin = (event) => {
    event.preventDefault()
    logIn({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const onClickSignup = (event) => {
    event.preventDefault()
    signUp({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }
  const navigateToBillingPortal = async () => {
    setLoadingBillingPortal(true)
    const res = await getStripeCustomerPortalSessionUrl()

    console.log(res)

    window.location.href = res.data.getStripeCustomerPortalSessionUrl
  }

  return (
    <div className="shadow-s flex flex-row items-center justify-between bg-white p-1">
      <div className="flex">
        <Link to="/" className="ml-2 flex items-center">
          <img
            src="/logo_full_black.png"
            alt="Logo"
            className="mr-2 h-5 w-auto"
          />
          <span
            className="text-xl font-semibold tracking-tight text-gray-800"
            translate="no"
          >
            LandingSite
          </span>
        </Link>
      </div>

      <div className="flex">
        {!isAuthenticated && (
          <div className="hidden items-center text-sm sm:flex">
            <button
              className="mr-4 rounded border p-1 px-2"
              onClick={onClickLogin}
            >
              Log In
            </button>
            <button
              className="mr-4 rounded border bg-green-500 p-1 px-2 text-white"
              onClick={onClickSignup}
            >
              Sign Up
            </button>
          </div>
        )}
        {isAuthenticated && (
          <div className="hidden items-center text-sm sm:flex">
            <Link
              to="/"
              className={`mr-4 ${
                window.location.pathname === '/' ? 'font-bold' : ''
              }`}
            >
              My Websites
            </Link>
            {/* <Link
              to="/domains"
              className={`mr-4 ${
                window.location.pathname === '/domains' ? 'font-bold' : ''
              }`}
            >
              My Domains
            </Link> */}
          </div>
        )}
        {isAuthenticated && website && website.finishedGeneratingAt && (
          <>
            {website && website.stripePlan === 'Free' && (
              <button
                className="mr-2 rounded border bg-green-500 p-1 px-2 text-white"
                onClick={() => navigateToPanel('settings', 'plan')}
              >
                <i className="fa-regular fa-sparkles mr-2 text-white"></i>
                <span>Upgrade</span>
                <span className="ml-1 hidden sm:inline">to Launch</span>
              </button>
            )}
            {website && website.stripePlan !== 'Free' && (
              <>
                {website && !website.Domain && !website.verifiedDomain && (
                  <button
                    className="mr-2 rounded border p-1 px-1 text-xs sm:text-base md:px-2"
                    onClick={() => {
                      onSetUpDomainClicked && onSetUpDomainClicked()
                    }}
                  >
                    <i className="fa-regular fa-exclamation-triangle mr-1 text-yellow-400 md:mr-2"></i>
                    <span>Set Up Your Domain</span>
                  </button>
                )}
                {websiteDomainInfo &&
                  websiteDomainInfo.Domain &&
                  !websiteDomainInfo.verifiedDomain &&
                  (websiteDomainInfo.Domain.verificationStatus ===
                    'Checking Nameservers' ||
                    websiteDomainInfo.Domain.verificationStatus ===
                      'Issuing SSL Certificate') && (
                    <button
                      className="mr-2 rounded border p-1 px-2 text-xs sm:text-base"
                      onClick={() => {
                        onSetUpDomainClicked && onSetUpDomainClicked()
                      }}
                    >
                      <i className="fa-regular fa-exclamation-triangle mr-2 animate-pulse text-yellow-400"></i>
                      <span>Verifying Domain...</span>
                    </button>
                  )}
                {websiteDomainInfo &&
                  websiteDomainInfo.Domain &&
                  !websiteDomainInfo.verifiedDomain &&
                  (websiteDomainInfo.Domain.verificationStatus ===
                    'Failed to Verify Nameservers' ||
                    websiteDomainInfo.Domain.verificationStatus ===
                      'Failed to Issue SSL Certificate') && (
                    <button
                      className="mr-2 rounded border p-1 px-2 text-xs sm:text-base"
                      onClick={() => {
                        onSetUpDomainClicked && onSetUpDomainClicked()
                      }}
                    >
                      <i className="fa-regular fa-exclamation-triangle mr-2 animate-pulse text-red-400"></i>
                      <span>Failed to Verify Domain</span>
                    </button>
                  )}
                {websiteDomainInfo &&
                  websiteDomainInfo.Domain &&
                  !websiteDomainInfo.verifiedDomain &&
                  websiteDomainInfo.Domain.verificationStatus ===
                    'Nameservers No Longer Pointed' && (
                    <button
                      className="mr-2 rounded border p-1 px-2 text-xs sm:text-base"
                      onClick={() => {
                        onSetUpDomainClicked && onSetUpDomainClicked()
                      }}
                    >
                      <i className="fa-regular fa-exclamation-triangle mr-2 animate-pulse text-red-400"></i>
                      <span>Domain Nameservers Changed</span>
                    </button>
                  )}
                {website && websiteDomainInfo.verifiedDomain && (
                  <button
                    className="mr-2 rounded border p-1 px-2"
                    onClick={() => {
                      setIsPublishing(true)
                    }}
                  >
                    {isPublishing && (
                      <>
                        <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                        <span>Launching...</span>
                      </>
                    )}
                    {!isPublishing && website && !website.proPublishedAt && (
                      <>
                        <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                        <span>Launch {websiteDomainInfo.verifiedDomain}</span>
                      </>
                    )}
                    {!isPublishing &&
                      website &&
                      website.proPublishedAt &&
                      hasUnpublishedChanges && (
                        <>
                          <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                          <span>Update {websiteDomainInfo.verifiedDomain}</span>
                        </>
                      )}
                    {!isPublishing &&
                      website &&
                      website.proPublishedAt &&
                      !hasUnpublishedChanges && (
                        <>
                          <i className="fa-regular fa-sparkles mr-2"></i>
                          <span>Up to date</span>
                        </>
                      )}
                  </button>
                )}
              </>
            )}
          </>
        )}
        {isAuthenticated && website && (
          <div className="group relative flex items-center">
            <button
              className="h-8 w-8 justify-center rounded bg-white p-1"
              onClick={() => navigateToPanel('settings')}
            >
              <i className="fa-regular fa-gear"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Settings
            </span>
          </div>
        )}
        {isAuthenticated && (
          <Popover className="relative flex">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex h-[32px] w-[32px] flex-grow items-center justify-center rounded-lg p-2 text-base font-medium text-gray-800 hover:border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-3/4 z-[80] mt-10 w-[180px] -translate-x-24 transform px-4 sm:w-[140px] sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <Link
                        to="/"
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                      >
                        <span>My Websites</span>
                        <i className="fa-solid fa-user"></i>
                      </Link>
                      {/* <Link
                        to="/domains"
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                      >
                        <span>My Domains</span>
                        <i className="fa-solid fa-globe"></i>
                      </Link> */}
                      <button
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                        onClick={navigateToBillingPortal}
                      >
                        <span>Billing</span>
                        {!loadingBillingPortal && (
                          <i className="fa-regular fa-credit-card"></i>
                        )}
                        {loadingBillingPortal && (
                          <i className="fa-regular fa-spinner-third animate-spin"></i>
                        )}
                      </button>
                      <button
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                        onClick={() => {
                          logOut({
                            logoutParams: {
                              returnTo: 'https://www.landingsite.ai/',
                            },
                          })
                        }}
                      >
                        <span>Log Out</span>
                        <i className="fa-solid fa-sign-out"></i>
                      </button>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </div>
    </div>
  )
}

export default Navbar
