import React from 'react'

const WebsiteLoadingPanel = ({
  onPickFavoriteImagesSelected,
}: {
  onPickFavoriteImagesSelected: () => void
}) => {
  return (
    <div className="overflow-scroll rounded bg-white px-4 py-20 shadow-md">
      <p className="mb-2 text-center font-semibold lg:text-xl">
        Our AI will generate text and images that will best fit your website
        based on your information.
      </p>
      <video className="h-[300px] w-full" autoPlay loop muted>
        <source
          src="https://customer-tk9sfxvz7forjbdh.cloudflarestream.com/10f66d1fbf7090c688cabe4471cd3b4e/downloads/default.mp4"
          type="video/mp4"
        />
      </video>
      {/* <div className="mt-2 text-center font-semibold lg:text-xl">
        <p>
          Or if you'd like to browse our gallery to choose your own images while
          you wait:
        </p>
        <button
          className="mt-8 rounded bg-blue-500 px-4 py-2 text-white"
          onClick={onPickFavoriteImagesSelected}
        >
          Pick Images From Gallery (Optional)
        </button>
      </div> */}
    </div>
  )
}

export default WebsiteLoadingPanel
