import { renderToStaticMarkup } from 'react-dom/server'

import { buildFontUrl } from 'src/lib/fonts'

// we use these for our app styles in the iframe, not embedded on prod sites
const styles = `

.hovered-element {
  outline: #3871E0 dashed 2px;
}

.clicked-element {
  outline: #3871E0 solid 2px;
}

.clicked-code-section {
  border: #3B82F6 solid 2px;
}
`

export const getInitialContent = ({
  cssVariables,
  fonts,
}: {
  cssVariables: string
  fonts?: string[]
}) => {
  return renderToStaticMarkup(
    <html className="h-full" lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          id="google-fonts-link"
          href={buildFontUrl(fonts)}
          rel="stylesheet"
        />
        <script
          src="https://kit.fontawesome.com/8e98006f77.js"
          crossOrigin="anonymous"
        ></script>
        <script src="https://cdn.tailwindcss.com"></script>
        <style>{`:root { ${cssVariables} }`}</style>
        <style>{styles}</style>
      </head>
      <body className="h-full bg-white">
        <div className="frame-root">
          <div className="frame-content"></div>
        </div>
      </body>
    </html>
  )
}
