import { useState, useEffect } from 'react'

// https://tailwindcss.com/docs/responsive-design
const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}

export function useBreakpoint(breakpoint: keyof typeof screens) {
  const [match, setMatch] = useState(false)

  useEffect(() => {
    const value = screens[breakpoint]
    const query = window.matchMedia(`(min-width: ${value})`)

    function listener(event: MediaQueryListEvent) {
      setMatch(event.matches)
    }

    setMatch(query.matches)

    query.addEventListener('change', listener)
    return () => query.removeEventListener('change', listener)
  }, [breakpoint])

  return match
}
