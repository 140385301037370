// forked from https://github.com/samuelmeuli/font-picker-react and converted to HeadlessUI combobox

import React, { useEffect, useState } from 'react'

import { Combobox } from '@headlessui/react'
import {
  FontManager,
  Font,
  Options,
  FONT_FAMILY_DEFAULT,
  Category,
} from '@samuelmeuli/font-manager'

interface FontPickerProps {
  apiKey: string
  activeFontFamily: string
  onChange: (font: Font) => void
  disabled?: boolean
  bodyFont?: string
}

export default function FontPicker({
  apiKey,
  activeFontFamily = FONT_FAMILY_DEFAULT,
  onChange = () => {},
  disabled = false,
  bodyFont,
}: FontPickerProps) {
  const [fonts, setFonts] = useState<Font[]>([])
  const [selectedFont, setSelectedFont] = useState<Font | null>(null)
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!activeFontFamily || typeof activeFontFamily !== 'string') {
      console.error('Invalid activeFontFamily:', activeFontFamily)
      //return
    }

    const options: Options = {
      pickerId: 'main',
      families: [],
      categories: [],
      scripts: [],
      variants: ['regular'],
      filter: () => true,
      limit: 50,
      sort: 'popularity',
    }

    const fontManager = new FontManager(
      apiKey,
      // hack so the font manager doesn't try to download the tailwind default font from google fonts
      activeFontFamily === bodyFont ? FONT_FAMILY_DEFAULT : activeFontFamily,
      options,
      onChange
    )

    fontManager
      .init()
      .then(() => {
        const fontList = Array.from(fontManager.getFonts().values())
        console.log('Fonts fetched:', fontList)

        if (fontList.length === 0) {
          console.log(
            'No fonts were fetched. Check your API key or network connection.'
          )
        }

        // Add activeFontFamily if it's not in the list
        const additionalFonts = [activeFontFamily]
          .filter(
            (family) =>
              family && !fontList.some((font) => font.family === family)
          )
          .map((family) => ({
            family,
            id: family,
            category: 'sans-serif' as Category,
            scripts: ['latin'],
            variants: ['regular'],
          }))

        const combinedFontList = [...additionalFonts, ...fontList]

        // Add bodyFont to the list without attempting to load it
        if (
          bodyFont &&
          !combinedFontList.some((font) => font.family === bodyFont)
        ) {
          combinedFontList.push({
            family: bodyFont,
            id: bodyFont,
            category: 'sans-serif' as Category,
            scripts: ['latin'],
            variants: ['regular'],
          })
        }

        const sortedFontList = combinedFontList.sort((a, b) =>
          a.family.localeCompare(b.family)
        )
        setFonts(sortedFontList as Font[])
        const newFont =
          (sortedFontList as Font[]).find(
            (font) => font.family === activeFontFamily
          ) || null

        if (!newFont) {
          console.error('Selected font not found:', activeFontFamily)
        }
        setSelectedFont(newFont)
      })
      .catch((err: Error) => {
        console.error('Error fetching fonts:', err)
      })
  }, [apiKey, activeFontFamily, onChange, bodyFont])

  useEffect(() => {
    // Load fonts into the document, excluding bodyFont
    fonts.forEach((font) => {
      if (!font.family) {
        console.error('Invalid font:', font)
        //return
      }
      if (font.family !== bodyFont) {
        const link = document.createElement('link')
        link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(
          font.family
        )}`
        link.rel = 'stylesheet'
        document.head.appendChild(link)
      }
    })
  }, [fonts, bodyFont])

  const filteredFonts =
    query === ''
      ? fonts
      : fonts.filter((font) =>
          font.family.toLowerCase().includes(query.toLowerCase())
        )

  const getDisplayValue = (font: Font | null) => {
    if (!font) return ''
    return font.family.split(',')[0].trim()
  }

  return (
    <Combobox
      value={selectedFont}
      onChange={(font: Font) => {
        setSelectedFont(font)
        onChange(font.family === bodyFont ? null : font)
      }}
      disabled={disabled}
    >
      <div className="relative text-sm">
        <Combobox.Input
          className={`w-full rounded bg-white py-1 pl-3 pr-6 text-left text-sm font-normal ${
            disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100'
          } focus:outline-none focus-visible:border-blue-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600`}
          displayValue={getDisplayValue}
          onChange={(event) => setQuery(event.target.value)}
          style={{ fontFamily: selectedFont?.family }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <i
            className={`fa-regular fa-angle-down ${
              disabled ? 'opacity-50' : ''
            }`}
            aria-hidden="true"
          ></i>
        </Combobox.Button>
        <Combobox.Options className="absolute z-10 mt-3 max-h-60 w-40 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredFonts.map((font) => (
            <Combobox.Option
              key={font.family}
              value={font}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-8 pr-4 ${
                  active ? 'bg-gray-200 text-[#333]' : 'text-gray-900'
                }`
              }
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    }`}
                    style={{ fontFamily: font.family }}
                  >
                    {getDisplayValue(font)}
                  </span>
                  {selected ? (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                        active ? 'text-[#333]' : 'text-[#333]'
                      }`}
                    >
                      <i className="fa-solid fa-check" aria-hidden="true"></i>
                    </span>
                  ) : null}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
